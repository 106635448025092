import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { toast, ToastContainer } from 'react-toastify';
import { UserContext } from '../utils/UserContext'; 
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import FoodItem from './FoodItem';
import { TextField } from '@mui/material';
import './CreateOrder.css';
import './FoodItem.css';

const config = require('../../config');

function CreateOrder() {
  const [menuItems, setMenuItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [location, setLocation] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const { user, updateUser } = useContext(UserContext);
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [orderTimeRestriction, setOrderTimeRestriction] = useState(true); // Implicit restricția este activă
  const navigate = useNavigate();

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const year = today.getFullYear();
    return `${day}.${month}.${year}`;
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let isMounted = true; // Flag to avoid setting state if component is unmounted

    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(`${config.BACKEND_URL}/menu`, { signal }); 
        if (isMounted) {
          setMenuItems(response.data.filter(item => item.available));
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error('Error fetching menu items', error);
        }
      }
    };
    
    const verifyUser = async () => {
      if (isUserVerified) return; // If user is already verified, do not proceed

      
      if (!cookies.jwt) {
        navigate("/login");
      } else {
        try {
          const { data } = await axios.post(
            `${config.BACKEND_URL}`,
            {},
            {
              withCredentials: true,
            }
          );

          if (!data.status) {
            removeCookie("jwt");
            navigate("/login");
          } else if (isMounted) {
            setCookie('user', {
              userID: data.userID,
              userType: data.userType,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              age: data.age,
              gender: data.gender,
              faculty: data.faculty,
              balance: data.balance,
              idCard: data.idCard,
              profilePicture: data.profilePicture
            }, { path: '/' });
            setIsUserVerified(true); // Set user as verified
          }
        } catch (error) {
          console.error("Error verifying user:", error);
        }
      }
    };

    const fetchOrderTimeRestriction = async () => {
      try {
        const response = await axios.get(`${config.BACKEND_URL}/order-time-restriction`, { signal });
        if (isMounted) {
          setOrderTimeRestriction(response.data.orderTimeRestriction);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error('Error fetching order time restriction:', error);
        }
      }
    };

    verifyUser();
    fetchMenuItems();
    fetchOrderTimeRestriction();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [cookies.jwt, navigate, updateUser, setCookie, removeCookie]);

  const checkOrderTime = () => {
    if (!orderTimeRestriction) return true;

    const currentTime = new Date();
    const start = new Date();
    start.setHours(12, 30, 0); // 12:30 PM

    const end = new Date();
    end.setHours(15, 0, 0); // 3:00 PM

    return currentTime >= start && currentTime <= end;
  };

  const handleOrderSubmit = (e) => {
    e.preventDefault();
    if (!checkOrderTime()) {
      toast.error('Puteți plasa comenzi doar între orele 12:30 și 15:00.', { theme: 'light' });
      return;
    }

    handleCheckout();
  };

  const toggleOrderTimeRestriction = async () => {
    try {
      const newOrderTimeRestriction = !orderTimeRestriction;
      const response = await axios.post(`${config.BACKEND_URL}/order-time-restriction`, {
        orderTimeRestriction: newOrderTimeRestriction
      });
      setOrderTimeRestriction(response.data.orderTimeRestriction);
      toast.success(`Restricția de timp pentru plasarea comenzilor a fost ${newOrderTimeRestriction ? 'activată' : 'dezactivată'}.`, { theme: 'light' });
    } catch (error) {
      console.error('Error updating order time restriction:', error);
      toast.error('A apărut o eroare la actualizarea restricției de timp.', { theme: 'light' });
    }
  };

  const handleAddToOrder = (item) => {
    const existingItem = orderItems.find(orderItem => orderItem.menuItem._id === item._id);
    if (existingItem) {
      if (existingItem.quantity >= 10) {
        toast.error('Poți adăuga în comandă doar maxim 10 produse de același tip.', { theme: 'light' });
        return;
      }
      setOrderItems(orderItems.map(orderItem =>
        orderItem.menuItem._id === item._id ? { ...orderItem, quantity: orderItem.quantity + 1 } : orderItem
      ));
    } else {
      setOrderItems([...orderItems, { menuItem: item, quantity: 1 }]);
    }
    setTotalPrice(totalPrice + item.price);
  };

  const handleRemoveFromOrder = (item) => {
    const existingItem = orderItems.find(orderItem => orderItem.menuItem._id === item._id);
    if (existingItem.quantity > 1) {
      setOrderItems(orderItems.map(orderItem =>
        orderItem.menuItem._id === item._id ? { ...orderItem, quantity: orderItem.quantity - 1 } : orderItem
      ));
      setTotalPrice(totalPrice - item.price);
    } else {
      setOrderItems(orderItems.filter(orderItem => orderItem.menuItem._id !== item._id));
      setTotalPrice(totalPrice - item.price);
    }
  };

  const handleCheckout = async () => {
    if (isSubmitting) {
      toast.error('Vă rugăm să așteptați înainte de a plasa o nouă comandă.', { theme: 'light' });
      return;
    }
  
    if (orderItems.length === 0) {
      toast.error('Vă rugăm să adăugați produse în coș înainte de a plasa comanda', { theme: 'light' });
      return;
    }
  
    if (!user || !user.userID) {
      console.error('User ID is not available');
      return;
    }
  
    if (!location) {
      toast.error('Vă rugăm să selectați locația', { theme: 'light' });
      return;
    }
  
    if (!paymentMethod) {
      toast.error('Vă rugăm să selectați metoda de plată', { theme: 'light' });
      return;
    }
  
    try {
      setIsSubmitting(true);
      const response = await axios.post(`${config.BACKEND_URL}/order`, { 
        user: user.userID,
        items: orderItems,
        totalPrice,
        paymentMethod,
        location 
      });
      
      const checkoutResponse = await axios.post(`${config.BACKEND_URL}/order/checkout`, {
        orderId: response.data.orderID,
        paymentMethod,
        userId: user.userID
      });
  
      toast.success("Comanda a fost plasată cu succes!", { theme: 'light' });
      setIsCartOpen(false);
      navigate('/');
      resetOrder();
      setTimeout(() => {
        setIsSubmitting(false);
      }, 5000);
    } catch (error) {
      console.error('Error creating order:', error);
      toast.error('Sold insuficient', { theme: 'light' });
      setIsSubmitting(false);
    }
  };

  const resetOrder = () => {
    setOrderItems([]);
    setTotalPrice(0);
    setLocation('');
    setPaymentMethod('');
  };
  
  const toggleCart = () => {
    if (isCartOpen) {
      
    }
    setIsCartOpen(!isCartOpen);
  };

  const itemsByCategory = menuItems.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {});

  return (
    <div>
      <h2 className="category-title">{getCurrentDate()}</h2>
      <div>
        {Object.keys(itemsByCategory).map(category => (
          <div key={category}>
            <h4 className="category-title">{category}</h4>
            <div className="food-item-container">
              {itemsByCategory[category].map(item => (
                <FoodItem
                  key={item._id}
                  image={`${config.BACKEND_URL}${item.imageUrl}`}
                  name={item.name}
                  price={item.price}
                  desc={item.description}
                  quantity={item.quantity}
                  unit={item.unit}
                  onAdd={() => handleAddToOrder(item)}
                  onRemove={() => handleRemoveFromOrder(item)}
                  itemCount={orderItems.find(orderItem => orderItem.menuItem._id === item._id)?.quantity || 0}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <IconButton
        onClick={toggleCart}
        className="cart-button"
        style={{ position: 'fixed', bottom: '20px', right: '20px', backgroundColor: '#2E7D3D', color: 'white' }}
      >
        <ShoppingCartIcon />
      </IconButton>
      <Modal
        open={isCartOpen}
        onClose={toggleCart}
        aria-labelledby="order-summary-title"
        aria-describedby="order-summary-description"
      >
        <div className="modal-content-summary">
          <IconButton
            onClick={toggleCart}
            className="close-button"
            style={{position: 'absolute'}}
          >
            <CloseIcon />
          </IconButton>
          <h3 className="order-summary-title" id="order-summary-title">Sumarul comenzii</h3>
          <div className="scrollable-cart-items">
            <ul id="order-summary-description">
              {orderItems.map(orderItem => (
                <li key={orderItem.menuItem._id} className="cart-item">
                  <img src={`${config.BACKEND_URL}${orderItem.menuItem.imageUrl}`} alt={orderItem.menuItem.name} />
                  <span>{orderItem.menuItem.name} - {orderItem.quantity} x {orderItem.menuItem.price.toFixed(2)} RON</span>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => handleAddToOrder(orderItem.menuItem)}
                    style={{ minWidth: '36px', marginRight: '8px' }}
                  >
                    +
                  </Button>
                  <Button
                    variant="contained"
                    color ="error"
                    size="small"
                    onClick={() => handleRemoveFromOrder(orderItem.menuItem)}
                    style={{ minWidth: '36px', marginRight: '16px'}}
                  >
                    -
                  </Button>
                </li>
              ))}
            </ul>
          </div>
          <p className="order-summary-title">Total de achitat: {totalPrice.toFixed(2)} RON</p>
            <TextField
            select
            required
            id="outlined-required"
            label="Locație"
            name="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            SelectProps={{ native: true }}
            style={{ marginBottom: '16px' }}
          >
            <option value=""></option>
            <option value="Universitate">Minicantină Universitate</option>
            <option value="Corp J">Punctul de servire a mesei "Corp J"</option>
            <option value="Campusul studentesc Al. I. Cuza">Cantina studențească</option>
          </TextField>
          <TextField
            select
            required
            id="outlined-required"
            label="Metoda de plată"
            name="paymentMethod"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            SelectProps={{ native: true }}
            style={{ marginBottom: '16px' }}
          >
            <option value=""></option>
            <option value="cash">Cash</option>
            <option value="balance">Sold</option>
          </TextField>
          <Button variant="contained" color="primary" fullWidth onClick={handleOrderSubmit} style={{textTransform:"none", fontFamily:'Outfit, sans-serif'}}>
            Trimite comanda
          </Button>
          
        </div>
      </Modal>
      {user && user.userType === 'Admin' && (
        <IconButton
          className="work-time-button"
          style={{height:'56px', width:'56px', position: 'fixed', bottom: '90px', right: '20px', backgroundColor: orderTimeRestriction ? '#2E7D3D' : '#D32F2F', color: 'white' }}
          onClick={toggleOrderTimeRestriction}
        >
          🕒
        </IconButton>
      )}
      
    </div>
  );
}

export default CreateOrder;
