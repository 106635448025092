import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import TextField from '@mui/material/TextField';
import Button from '@mui/joy/Button';
import { styled } from '@mui/joy';
import 'react-toastify/dist/ReactToastify.css';
import "./Login.css";

const config = require('../../config');

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function Login() {
  const [cookies, setCookie] = useCookies([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.jwt) {
      navigate("/"); 
    }
  }, [cookies, navigate]);

  const [values, setValues] = useState({ email: "", password: "" });
  const generateError = (error) =>
    toast.error(error, {
      position: "top-right",
    });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await axios.post(
        `${config.BACKEND_URL}/login`,
        { ...values },
        { withCredentials: true }
      );

      if (data.userId) {
        navigate("/verify-code", { state: { userId: data.userId } }); // Redirecționează utilizatorul către pagina de verificare a codului
      }
    } catch (ex) {
      if (ex.response) {
        if (ex.response.status === 400) {
          generateError("Datele de logare sunt incorecte. Încearcă din nou.");
        } else if (ex.response.status === 403) {
          generateError("Contul tău nu a fost încă activat. Verifică-ți email-ul.");
        } else {
          generateError("A apărut o eroare la logare. Încearcă din nou.");
        }
      } else {
        generateError("A apărut o eroare la logare. Încearcă din nou.");
      }
    }
  };

  return (
    <div className="loginPage">
      <div className="container-login">
        <h2 className="formTitle">Loghează-te!</h2>
        <form onSubmit={handleSubmit}>
          <div className="inputs">
            <TextField
              required
              id="outlined-required"
              label="Email"
              name="email"
              type="email"
              value={values.email}
              onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
              sx={{ width: '100%' }}
            />
            <TextField
              required
              id="outlined-password-input"
              label="Parolă"
              name="password"
              type="password"
              value={values.password}
              onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
              sx={{ width: '100%' }}
            />
          </div>
          <button id="submitButton" type="submit">Trimite</button>
          <div className="displayLinks">
            <p>Nu ai cont? <Link to="/register">Înregistrează-te</Link></p>
            <p><Link to="/forgot-password">Ți-ai uitat parola?</Link></p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
