import React from 'react';
import './FoodItem.css';

const FoodItem = ({ image, name, price, desc, quantity, unit, onAdd, onRemove, itemCount }) => {
  return (
    <div className="food-item">
      <div className="food-item-img-container">
        <img src={image} alt={name} className="food-item-image" />
      </div>
      <div className="food-item-info">
        <div className="food-item-name-rating">
          <p>{name}</p>
        </div>
        <p className="food-item-desc">{desc}</p>
        <p className="food-item-quantity">{quantity} {unit}</p>
        <p className="food-item-price">{price.toFixed(2)} RON</p>
        <div className="food-item-counter">
          <button className="remove-button" onClick={onRemove}>-</button>
          <p>{itemCount}</p>
          <button className="add-button" onClick={onAdd}>+</button>
        </div>
      </div>
    </div>
  );
};

export default FoodItem;
