import React, { createContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

const UserContext = createContext();

const config = require('../../config');

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['user', 'jwt']);
  const [userVerified, setUserVerified] = useState(false);

  useEffect(() => {
    if (!user && cookies.user) {
      setUser(cookies.user);
    }
  }, [cookies.user, user]);

  const updateUser = async () => {
    if (cookies.jwt) {
      try {
        const { data } = await axios.post(
          `${config.BACKEND_URL}`,
          {},
          {
            withCredentials: true,
          }
        );

        

        if (data && data.status) {
          const userData = {
            userID: data.userID,
            userType: data.userType,
            email: data.email,
          };

          // Add optional fields if they exist
          const optionalFields = ['firstName', 'lastName', 'age', 'gender', 'faculty', 'balance', 'idCard', 'profilePicture'];
          optionalFields.forEach(field => {
            if (data[field]) {
              userData[field] = data[field];
            }
          });

          setCookie("user", userData, { path: "/", sameSite: 'None', secure: true });
          setUser(userData);
          setUserVerified(true);
        } else {
          console.error('No user data found in API response', data);
          handleLogout();
        }
      } catch (error) {
        console.error("Error verifying user:", error);
        handleLogout();
      }
    }
  };

  const handleLogout = () => {
    removeCookie("jwt", { path: "/" });
    removeCookie("user", { path: "/" });
    setUser(null);
    setUserVerified(false);
  };

  return (
    <UserContext.Provider value={{ user, setUser, updateUser, userVerified, handleLogout }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
