import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { UserContext } from '../utils/UserContext'; 
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import { styled } from '@mui/material/styles';
import './MyOrders.css';
import UseCustomMediaQuery from '../utils/UseCustomMediaQuery';

const config = require('../../config');

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  textAlign: 'center' 
}));

const CenteredTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center' 
}));

function MyOrders() {
  const isMobile = UseCustomMediaQuery('(max-width: 600px)');
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const { user, updateUser } = useContext(UserContext);
  const [cookies] = useCookies(["user"]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true; // Adăugați steagul isMounted

    const verifyAndFetchOrders = async () => {
      if (!cookies.jwt) {
        navigate("/login");
        return;
      }

      if (!user) {
        await updateUser();
      }

      const userId = cookies.user ? cookies.user.userID : null;
      if (!userId) {
        toast.error("Utilizatorul nu a fost găsit.", { theme: "light" });
        navigate("/login");
        return;
      }

      try {
        const response = await axios.get(
          `${config.BACKEND_URL}/orders/user/${userId}`
        );
        if (isMounted) { // Verificați dacă componentul este montat înainte de a seta starea
          setOrders(response.data);
          setFilteredOrders(response.data);
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching orders", error);
          toast.error("A apărut o eroare la încarcarea comenzilor.", { theme: "light" });
        }
      }
    };

    verifyAndFetchOrders();

    return () => {
      isMounted = false; // Setează isMounted la false când componentul se demontează
    };
  }, [cookies, navigate, updateUser, user, location]);

  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus(status);
    filterOrders(status, selectedLocation, selectedPaymentMethod);
  };

  const handleLocationChange = (e) => {
    const location = e.target.value;
    setSelectedLocation(location);
    filterOrders(selectedStatus, location, selectedPaymentMethod);
  };

  const handlePaymentMethodChange = (e) => {
    const paymentMethod = e.target.value;
    setSelectedPaymentMethod(paymentMethod);
    filterOrders(selectedStatus, selectedLocation, paymentMethod);
  };

  const filterOrders = (status, location, paymentMethod) => {
    let filtered = orders;
    if (status) {
      filtered = filtered.filter(order => order.status === status);
    }
    if (location) {
      filtered = filtered.filter(order => order.location === location);
    }
    if (paymentMethod) {
      filtered = filtered.filter(order => order.paymentMethod === paymentMethod);
    }
    setFilteredOrders(filtered);
  };

  const getLocationDisplayName = (location) => {
    switch(location) {
      case 'Universitate':
        return 'Minicantină Universitate';
      case 'Corp J':
        return 'Corp J - Campusul Științei';
      case 'Campusul studentesc Al. I. Cuza':
        return 'Cantina studențească - Complexul Studențesc "Al. I. Cuza"';
      default:
        return location;
    }
  };

  const getStatusDisplayName = (status) => {
    switch(status) {
      case 'new':
        return 'Comandă nouă';
      case 'in progress':
        return 'Comandă în curs de pregătire';
      case 'finished':
        return 'Comandă finalizată';
      case 'delivered':
        return 'Comandă ridicată';
      default:
        return status;
    }
  };

  const getPaymentTypeDisplayName = (paymentType) => {
    switch(paymentType) {
      case 'cash':
        return 'Cash';
      case 'balance':
        return 'Sold';
      default:
        return paymentType;
    }
  };

  return (
    <div className="my-orders-page">
      <h2 className="category-title">Comenzile mele</h2>
      <div className="search-form">
        <FormControl className="filter-select">
          <InputLabel>Status</InputLabel>
          <Select style={{width: isMobile ? '100%' : '350px'}} value={selectedStatus} onChange={handleStatusChange} label="Status">
            <MenuItem value="">Toate comenzile</MenuItem>
            <MenuItem value="new">Comandă nouă</MenuItem>
            <MenuItem value="in progress">Comandă în curs de pregătire</MenuItem>
            <MenuItem value="finished">Comandă finalizată</MenuItem>
            <MenuItem value="delivered">Comandă ridicată</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="filter-select">
          <InputLabel>Locație</InputLabel>
          <Select style={{width: isMobile ? '100%' : '350px'}} value={selectedLocation} onChange={handleLocationChange} label="Location">
            <MenuItem value="">Toate locațiile</MenuItem>
            <MenuItem value="Universitate">Minicantină Universitate</MenuItem>
            <MenuItem value="Corp J">Corp J - Campusul Științei</MenuItem>
            <MenuItem value="Campusul studentesc Al. I. Cuza">Cantina studențească</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="filter-select">
          <InputLabel>Metodă de plată</InputLabel>
          <Select style={{width: isMobile ? '100%' : '350px'}} value={selectedPaymentMethod} onChange={handlePaymentMethodChange} label="Metodă de plată">
            <MenuItem value="">Toate metodele de plată</MenuItem>
            <MenuItem value="cash">Cash</MenuItem>
            <MenuItem value="balance">Sold</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Nr. Produse</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Locație</StyledTableCell>
              <StyledTableCell>Metodă de plată</StyledTableCell>
              <StyledTableCell>Total de plată</StyledTableCell>
              <StyledTableCell>Acțiuni</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOrders.map((order) => (
              <TableRow key={order._id}>
                <CenteredTableCell>{order.orderID}</CenteredTableCell>
                <CenteredTableCell>{order.items.length}</CenteredTableCell>
                <CenteredTableCell>{getStatusDisplayName(order.status)}</CenteredTableCell>
                <CenteredTableCell>{getLocationDisplayName(order.location)}</CenteredTableCell>
                <CenteredTableCell>{getPaymentTypeDisplayName(order.paymentMethod)}</CenteredTableCell>
                <CenteredTableCell>{(order.totalPrice).toFixed(2)} RON</CenteredTableCell>
                <CenteredTableCell className="table-actions-cell">
                  <Button style={{textTransform:"none", fontFamily:'Outfit, sans-serif'}} variant="contained" color="primary" onClick={() => navigate(`/order/${order._id}`)}>
                    Vizualizează comanda
                  </Button>
                </CenteredTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
    </div>
  );
}

export default MyOrders;
