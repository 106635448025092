import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { format } from 'date-fns';
import EditProfile from "./EditProfile";
import UpdateProfilePicture from "./UpdateProfilePicture";
import TextField from "@mui/material/TextField";

import "./UserProfile.css";

const config = require('../../config');


export default function UserProfile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [faculty, setFaculty] = useState("");
  const [accountCreationDate, setAccountCreationDate] = useState("");
  const [balance, setBalance] = useState("");
  const [idCard, setIdCard] = useState("");
  const [idCardImage, setIdCardImage] = useState("");
  const [userType, setUserType] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [currentUserType, setCurrentUserType] = useState("");
  const [currentUserID, setCurrentUserID] = useState("");

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isBalanceUpdateModalOpen, setIsBalanceUpdateModalOpen] = useState(false);
  const [isUpdateProfilePictureModalOpen, setIsUpdateProfilePictureModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    age: "",
    gender: "",
    faculty: "",
    balance: "",
    idCard: "",
    userType: "",
    profilePicture: "",
  });

  const [balanceChange, setBalanceChange] = useState("");
  const [balanceError, setBalanceError] = useState("");
  const [activeTab, setActiveTab] = useState('info');

  useEffect(() => {
    const verifyUser = async () => {
      if (!cookies.jwt) {
        navigate("/login");
      } else {
        try {
          const { data } = await axios.post(
            `${config.BACKEND_URL}`,
            {},
            {
              withCredentials: true,
            }
          );

          if (!data.status) {
            removeCookie("jwt");
            navigate("/login");
          } else {
           
            setCookie('user', {
              userID: data.userID,
              userType: data.userType,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              age: data.age,
              gender: data.gender,
              faculty: data.faculty,
              balance: data.balance,
              idCard: data.idCard,
              profilePicture: data.profilePicture
            }, { path: '/' });

            setCurrentUserType(data.userType);
            setCurrentUserID(data.userID);

          }
        } catch (error) {
          console.error("Error verifying user:", error);
        }
      }
    };

    const fetchUserDetails = async (userID) => {
      try {
        const { data } = await axios.get(`${config.BACKEND_URL}/user/${userID}`);
        setEmail(data.email);
        setAge(data.age);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setGender(data.gender);
        setFaculty(data.faculty);
        setIdCard(data.idCard);
        setIdCardImage(data.idCardImage);
        setUserType(data.userType);
        setProfilePicture(data.profilePicture);

        setFormData({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          age: data.age,
          gender: data.gender,
          faculty: data.faculty,
          balance: data.balance,
          idCard: data.idCard,
          userType: data.userType,
          profilePicture: data.profilePicture,
        });

        const date = new Date(data.accountCreationDate);
        setAccountCreationDate(format(date, 'dd/MM/yyyy'));

        setBalance(data.balance);

      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    verifyUser();

    if (id) {
      fetchUserDetails(id);
    }

    if (localStorage.getItem('showBalanceSuccessToast')) {
      toast.success('Sold-ul a fost actualizat cu succes!', { theme: "light" });
      localStorage.removeItem('showBalanceSuccessToast');
    }

  }, [cookies, navigate, removeCookie, id]);

  const logOut = async () => {
    try {
      await axios.get(`${config.BACKEND_URL}/logout`, { withCredentials: true });
      removeCookie("jwt");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
      toast.error("A apărut o eroare la delogare", { theme: "light" });
    }
  };

  const handleEditProfile = () => {
    setIsEditModalOpen(true);
  };

  const handleDeleteProfile = async () => {
    try {
      
      const currentUserIDNumber = Number(currentUserID);
      const idNumber = Number(id);
  
      if (currentUserIDNumber === idNumber) {
        toast.error("Nu puteți șterge profilul pe care sunteți conectat", { theme: "light" });
        return;
      }
  
      await axios.delete(`${config.BACKEND_URL}/erase-user/${id}`);
      toast("Contul și comenzile asociate au fost șterse cu succes!", { theme: "light" });
      navigate("/");
    } catch (error) {
      console.error("Error deleting profile and orders:", error);
      toast.error("A apărut o eroare la ștergerea contului", { theme: "light" });
    }
  };
  

  const handleModalClose = () => {
    setIsEditModalOpen(false);
    window.location.reload(); 
  };

  const handleBalanceUpdateModalClose = () => {
    setIsBalanceUpdateModalOpen(false);
    window.location.reload();  
  };

  const handleFormSubmit = async (updatedFormData) => {
    try {
      const { data } = await axios.put(`${config.BACKEND_URL}/user/${id}`, updatedFormData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      
      setIsEditModalOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Error updating profile:", error);
      
    }
  };

  const handleBalanceUpdate = async (event) => {
    event.preventDefault();
    const numericBalanceChange = parseFloat(balanceChange);

    if (!/^-?\d+$/.test(balanceChange) || numericBalanceChange > 999999999999999) {
      setBalanceError("Valoarea nu poate fi mai mare de 999999999999999 și trebuie să fie un număr valid.");
      return;
    }

    if (numericBalanceChange < 0 && Math.abs(numericBalanceChange) > balance) {
      setBalanceError("Nu poți scădea mai mult decât soldul curent.");
      return;
    }

    try {
      const { data } = await axios.put(`${config.BACKEND_URL}/user/${id}/updateBalance`, { balanceChange });
      localStorage.setItem('showBalanceSuccessToast', 'true'); 
      setBalance(data.balance);
      setIsBalanceUpdateModalOpen(false);
      window.location.reload();  
    } catch (error) {
      console.error("Error updating balance:", error);
      toast.error("Sold-ul nu a fost actualizat", { theme: "light" });
    }
  };

  const handleUpdateProfilePicture = () => {
    setIsUpdateProfilePictureModalOpen(true);
  };

  const handleUpdateProfilePictureModalClose = () => {
    setIsUpdateProfilePictureModalOpen(false);
  };

  const badgePath = `${config.BACKEND_URL}/uploads/badges/${idCard}`;
  const profilePicturePath = `${config.BACKEND_URL}/uploads/profilePictures/${profilePicture}`;

  return (
    <>
      <div className="profile-page">
        <div className="profile-sidebar">
          <div className="user-picture-container">
          <img src={profilePicturePath} alt="Profile" className="user-picture" />
          </div>
          <h2>{firstName} {lastName}</h2>
          <div className={`user-tag ${userType.toLowerCase()}`}>{userType}</div>
          <img src={badgePath} alt="ID Card" className="id-card" />
          {currentUserID.toString() === id && ( 
            <button onClick={handleUpdateProfilePicture} className="update-profile-picture-btn">Actualizează poza de profil</button>
          )}
        </div>
        <div className="profile-content">
          <div className="tabs">
            <div className={`tab ${activeTab === 'info' ? 'active' : ''}`} onClick={() => setActiveTab('info')}>Informatii profil</div>
            {currentUserType === "Admin" && (
              <div className={`tab ${activeTab === 'moderation' ? 'active' : ''}`} onClick={() => setActiveTab('moderation')}>Moderare cont</div>
            )}
          </div>
          {activeTab === 'info' && (
            <div className="profile-info">
              <p><strong>Nume:</strong> {lastName}</p>
              <p><strong>Prenume:</strong> {firstName}</p>
              <p><strong>Email:</strong> {email}</p>
              <p><strong>Gen:</strong> {gender}</p>
              <p><strong>Varsta:</strong> {age}</p>
              <p><strong>Facultate:</strong> {faculty}</p>
              <p><strong>Sold:</strong> {balance != null ? Number(balance).toFixed(2) : '0.00'} RON</p>
              <p><strong>Data crearii:</strong> {accountCreationDate}</p>
            </div>
          )}
          {activeTab === 'moderation' && (
            <div className="profile-moderation">
              <div className="admin-actions">
                <button onClick={handleEditProfile}>Editare profil</button>
                <button onClick={handleDeleteProfile}>Șterge profil</button>
                <button onClick={() => setIsBalanceUpdateModalOpen(true)}>Actualizează sold</button>
              </div>
            </div>
          )}
        </div>
      </div>
     
      <EditProfile
        isOpen={isEditModalOpen}
        onRequestClose={handleModalClose}
        onSubmit={handleFormSubmit}
        formData={formData}
        setFormData={setFormData}
      />
      <UpdateProfilePicture
        isOpen={isUpdateProfilePictureModalOpen}
        onRequestClose={handleUpdateProfilePictureModalClose}
        userID={currentUserID}
        onUpdate={(newProfilePicture) => setProfilePicture(newProfilePicture)}
      />
      {isBalanceUpdateModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Actualizează sold</h2>
            <form onSubmit={handleBalanceUpdate}>
              <TextField
                type="text"
                value={balanceChange}
                onChange={(e) => setBalanceChange(e.target.value)}
                placeholder="Introduceți suma"
                required
                fullWidth
                margin="normal"
                onKeyPress={(e) => {
                  if (!/^[-0-9]$/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                onWheel={(e) => e.target.blur()}
                error={!!balanceError}
                helperText={balanceError}
              />
              <button type="submit">Actualizează</button>
              <button type="button" onClick={handleBalanceUpdateModalClose}>Anulează</button>
            </form>
          </div>
        </div>
      )}
    
    </>
  );
}
