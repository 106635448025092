import React, { useState, useContext, useEffect, useRef } from 'react';
import './Navbar.css';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { UserContext } from '../utils/UserContext'; 
import { Link } from 'react-router-dom';

const config = require('../../config');

const Navbar = ({ toggleSidebar }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const navigate = useNavigate();
  const { user, updateUser, userVerified, handleLogout } = useContext(UserContext); 
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogoutClick = async () => {
    try {
      await axios.get(`${config.BACKEND_URL}/logout`, { withCredentials: true });
      handleLogout();
      navigate('/login');
      setDropdownVisible(false); 
    } catch (error) {
      console.error('Error logging out', error);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownVisible]);

  useEffect(() => {
    if (!userVerified) {
      updateUser();
    }
  }, [userVerified, updateUser]);

  const userID = cookies.user?.userID;

  return (
    <div className="navbar">
      <button className="menu-button" onClick={toggleSidebar}>
        ☰
      </button>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <div className="page-title">Cantina studențească UDJG</div>
      </Link>
      <div className="profile-container" ref={dropdownRef}>
        {user ? (
          <>
            <img
              src={`${config.BACKEND_URL}/uploads/profilePictures/${user.profilePicture}`}
              alt={`${user.firstName} ${user.lastName}`}
              className="profile-thumbnail"
              onClick={toggleDropdown}
            />
            {dropdownVisible && (
              <div className="dropdown">
                <img
                  src={`${config.BACKEND_URL}/uploads/profilePictures/${user.profilePicture}`}
                  alt={`${user.firstName} ${user.lastName}`}
                  className="profile-picture"
                />
                <span className="dropdown-username">{`${user.firstName} ${user.lastName}`}</span>
                <span className="dropdown-username">
                  Sold: {user.balance !== undefined ? user.balance.toFixed(2) : "0.00"} RON
                </span>
                <ul className="dropdown-options">
                  <li onClick={() => { navigate(`/user/${userID}`); setDropdownVisible(false); }}>
                    <span className="icon">👤</span>
                    <span>Profilul meu</span>
                  </li>
                  <li onClick={() => { navigate('/my-orders'); setDropdownVisible(false); }}>
                    <span className="icon">🛒</span>
                    <span>Comenzile mele</span>
                  </li>
                  <li onClick={handleLogoutClick}>
                    <span className="icon">🚪</span>
                    <span>Delogheaza-ma</span>
                  </li>
                </ul>
              </div>
            )}
          </>
        ) : (
          <span>Not logged in</span>
        )}
      </div>
    </div>
  );
};

export default Navbar;
