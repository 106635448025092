import React, { useState, useContext } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../utils/UserContext'; 
import { useCookies } from 'react-cookie'; 
import { Modal, Box, TextField, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './ReportIssue.css'; 
import UseCustomMediaQuery from '../utils/UseCustomMediaQuery';

const config = require('../../config');

const ReportIssue = ({ isOpen, onClose }) => {
  const isMobile = UseCustomMediaQuery('(max-width: 600px)');
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%' : '30%',
    height: isMobile ? '45%' : 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    borderRadius: 2,
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  };

  const [issue, setIssue] = useState('');
  const [errors, setErrors] = useState({});
  const { user } = useContext(UserContext); 
  const [cookies] = useCookies(['jwt']); 
  const [isSubmitting, setIsSubmitting] = useState(false); 

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!issue) {
      newErrors.issue = 'Acest câmp este obligatoriu';
    } else if (issue.length > 500) {
      newErrors.issue = 'Descrierea problemei nu poate depăși 500 de caractere';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toast.error('Există erori în formular. Vă rugăm să le corectați înainte de a trimite.');
      return;
    }

    if (isSubmitting) {
      toast.error('Vă rugăm să așteptați înainte de a trimite o altă problemă.', { theme: 'light' });
      return;
    }

    try {
      setIsSubmitting(true);
      await axios.post(`${config.BACKEND_URL}/report-issue`,
        { issue },
        {
          headers: {
            Authorization: `Bearer ${cookies.jwt}` 
          }
        }
      );
      toast.success('Problema a fost trimisă cu succes!', { theme: 'light' });
      setIssue('');
      onClose();
      setTimeout(() => {
        setIsSubmitting(false);
      }, 10000);
    } catch (error) {
      toast.error('Eroare la trimiterea problemei. Încercați din nou.', { theme: 'light' });
      setIsSubmitting(false);
    }
  };

  const handleIssueChange = (e) => {
    const value = e.target.value;
    if (value.length <= 500) {
      setIssue(value);
      if (value.length === 500) {
        setErrors({ issue: 'Descrierea problemei nu poate depăși 500 de caractere' });
      } else {
        setErrors({});
      }
    } else {
      setErrors({ issue: 'Descrierea problemei nu poate depăși 500 de caractere' });
    }
  };

  const handleClose = () => {
    setIssue('');
    setErrors({});
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '16px', top: '16px' }}
        >
          <CloseIcon />
        </IconButton>
        <h2 className="aliniere">Raportează o problemă</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Descrierea problemei"
            value={issue}
            onChange={handleIssueChange}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
            error={!!errors.issue}
            helperText={errors.issue}
            inputProps={{ maxLength: 500 }} 
          />
          <Button style={{ textTransform: "none", fontFamily: 'Outfit, sans-serif' }} type="submit" variant="contained" color="primary" fullWidth>
            Trimite
          </Button>
        </form>
        
      </Box>
    </Modal>
  );
};

export default ReportIssue;
