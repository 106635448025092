import React, { useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/joy/Button";
import SvgIcon from '@mui/joy/SvgIcon';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from '@mui/joy';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./EditProfile.css";

const config = require('../../config');


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: 2,
  msOverflowStyle: 'none',  // IE and Edge
  scrollbarWidth: 'none',  // Firefox
};

export default function EditProfile({ isOpen, onRequestClose, onSubmit, formData, setFormData }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePreview, setProfilePreview] = useState(null);
  const [initialBirthDate, setInitialBirthDate] = useState('');
  const [errors, setErrors] = useState({});
  const birthDateValidationTimeout = useRef(null);

  useEffect(() => {
    if (formData.idCard) {
      setPreview(`${config.BACKEND_URL}/uploads/badges/${formData.idCard}`);
    }
    if (formData.profilePicture) {
      setProfilePreview(`${config.BACKEND_URL}/uploads/profilePictures/${formData.profilePicture}`);
    }
  }, [formData.idCard, formData.profilePicture]);

  useEffect(() => {
    if (isOpen) {
      setFormData((prevData) => ({
        ...prevData,
        birthDate: prevData.birthDate || '',
        balance: prevData.balance || '',
      }));
      setInitialBirthDate(formData.birthDate || '');
    }
  }, [isOpen, setFormData, formData.birthDate]);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
    return age;
  };

  const validateName = (name) => {
    return /^[a-zA-Z\s\-]*$/.test(name);
  };

  const validateFileExtension = (file) => {
    const validExtensions = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    return validExtensions.includes(file.type);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    if ((name === 'firstName' || name === 'lastName') && value.length > 30) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Nu poate avea mai mult de 30 de caractere',
      }));
      return;
    } else if (name === 'email' && value.length > 50) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Nu poate avea mai mult de 50 de caractere',
      }));
      return;
    } else if (name === 'firstName' || name === 'lastName') {
      if (!validateName(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Doar litere, spații și caracterul "-" sunt permise',
        }));
        return;
      }
    } else if (name === 'birthDate') {
      const dateParts = value.split("-");
      if (dateParts[0] && dateParts[0].length > 4) {
        dateParts[0] = dateParts[0].slice(0, 4);
        e.target.value = dateParts.join("-");
      }

      if (birthDateValidationTimeout.current) {
        clearTimeout(birthDateValidationTimeout.current);
      }
      birthDateValidationTimeout.current = setTimeout(() => {
        if (value.length === 10) {
          const age = calculateAge(value);
          if (age < 18) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              birthDate: 'Trebuie să ai cel puțin 18 ani',
            }));
          } else if (age > 100) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              birthDate: 'Nu poți avea mai mult de 100 de ani',
            }));
          }
        }
      }, 500); 
    } else if (name === 'balance') {
      if (value > 999999999999999) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          balance: 'Valoarea nu poate fi mai mare de 999999999999999',
        }));
        return;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === 'birthDate') {
      const dateParts = value.split("-");
      if (dateParts[0] && dateParts[0].length > 4) {
        dateParts[0] = dateParts[0].slice(0, 4);
        e.target.value = dateParts.join("-");
      }
    }
  };

  const handleKeyPress = (e) => {
    const { name } = e.target;
    if (name === 'balance' && !/^[0-9]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && !validateFileExtension(file)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        idCard: 'Doar fișiere de tip imagine sunt permise (JPEG, PNG, GIF, WEBP)',
      }));
      toast.error('Doar fișiere de tip imagine sunt permise (JPEG, PNG, GIF, WEBP)', { theme: 'light' });
      return;
    }
    setSelectedFile(file);
    setPreview(file ? URL.createObjectURL(file) : null);
    setErrors((prevErrors) => ({
      ...prevErrors,
      idCard: '',
    }));
  };

  const handleProfileFileChange = (e) => {
    const file = e.target.files[0];
    if (file && !validateFileExtension(file)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        profilePicture: 'Doar fișiere de tip imagine sunt permise (JPEG, PNG, GIF, WEBP)',
      }));
      toast.error('Doar fișiere de tip imagine sunt permise (JPEG, PNG, GIF, WEBP)', { theme: 'light' });
      return;
    }
    setProfilePicture(file);
    setProfilePreview(file ? URL.createObjectURL(file) : null);
    setErrors((prevErrors) => ({
      ...prevErrors,
      profilePicture: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'Acest câmp este obligatoriu';
    if (!formData.lastName) newErrors.lastName = 'Acest câmp este obligatoriu';
    if (!formData.email) newErrors.email = 'Acest câmp este obligatoriu';
    if (!formData.gender) newErrors.gender = 'Acest câmp este obligatoriu';
    if (!formData.faculty) newErrors.faculty = 'Acest câmp este obligatoriu';
    if (!formData.userType) newErrors.userType = 'Acest câmp este obligatoriu';
    if (!selectedFile && !formData.idCard) newErrors.idCard = 'Nu ați selectat nicio imagine pentru legitimație';
    if (!profilePicture && !formData.profilePicture) newErrors.profilePicture = 'Nu ați selectat nicio imagine pentru profil';

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toast.error('Există erori în formular. Vă rugăm să le corectați înainte de a trimite.', { theme: 'light' });
      return;
    }

    const updatedFormData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null && formData[key] !== '') {
        updatedFormData.append(key, formData[key]);
      }
    });
    if (selectedFile) {
      updatedFormData.append("idCard", selectedFile);
    }
    if (profilePicture) {
      updatedFormData.append("profilePicture", profilePicture);
    }

    if (formData.birthDate) {
      const age = calculateAge(formData.birthDate);
      updatedFormData.set("age", age);
    }

    try {
      await onSubmit(updatedFormData);
      localStorage.setItem('showSuccessToast', 'true');
      window.location.reload();
    } catch (error) {
      console.error('Error updating profile', error);
      toast.error('Eroare la actualizarea profilului', { theme: 'light' });
    }
  };

  useEffect(() => {
    if (localStorage.getItem('showSuccessToast')) {
      toast.success('Profilul a fost actualizat cu succes!');
      localStorage.removeItem('showSuccessToast');
    }
  }, []);

  const handleClose = () => {
    onRequestClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={style}>
        <IconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '16px', top: '16px' }}
        >
          <CloseIcon />
        </IconButton>
        <h2 style={{ textAlign: 'center' }}>Editează profilul</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Prenume"
            name="firstName"
            value={formData.firstName || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            label="Nume"
            name="lastName"
            value={formData.lastName || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
          <TextField
            label="Email"
            type="email"
            name="email"
            value={formData.email || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            label="Data nașterii"
            type="date"
            name="birthDate"
            value={formData.birthDate || ''}
            onChange={handleChange}
            onInput={handleInput}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            error={!!errors.birthDate}
            helperText={errors.birthDate}
          />
          <TextField
            label="Sex"
            select
            name="gender"
            value={formData.gender || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            SelectProps={{ native: true }}
            error={!!errors.gender}
            helperText={errors.gender}
          >
            <option value="Masculin">Masculin</option>
            <option value="Feminin">Feminin</option>
            <option value="Altul">Altul</option>
          </TextField>
          <TextField
            label="Facultate"
            name="faculty"
            value={formData.faculty || ''}
            onChange={handleChange}
            select
            fullWidth
            margin="normal"
            SelectProps={{ native: true }}
            error={!!errors.faculty}
            helperText={errors.faculty}
          >
            <option value=""></option>
            <option value="Facultatea de Inginerie">Facultatea de Inginerie</option>
            <option value="Facultatea de Arhitectură Navală">Facultatea de Arhitectură Navală</option>
            <option value="Facultatea de Știința și Ingineria Alimentelor">Facultatea de Știința și Ingineria Alimentelor</option>
            <option value="Facultatea de Automatică, Calculatoare, Inginerie Electrică și Electronică">Facultatea de Automatică, Calculatoare, Inginerie Electrică și Electronică</option>
            <option value="Facultatea de Educație Fizică și Sport">Facultatea de Educație Fizică și Sport</option>
            <option value="Facultatea de Litere">Facultatea de Litere</option>
            <option value="Facultatea de Științe și Mediu">Facultatea de Științe și Mediu</option>
            <option value="Facultatea de Istorie, Filosofie și Teologie">Facultatea de Istorie, Filosofie și Teologie</option>
            <option value="Facultatea de Economie și Administrarea Afacerilor">Facultatea de Economie și Administrarea Afacerilor</option>
            <option value="Facultatea de Drept și Științe Administrative">Facultatea de Drept și Științe Administrative</option>
            <option value="Facultatea de Medicină și Farmacie">Facultatea de Medicină și Farmacie</option>
            <option value="Facultatea de Arte">Facultatea de Arte</option>
            <option value="Facultatea Transfrontalieră">Facultatea Transfrontalieră</option>
          </TextField>
          <TextField
            label="Sold"
            type="number"
            name="balance"
            value={formData.balance || ''}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            fullWidth
            margin="normal"
            onWheel={(e) => e.target.blur()}
            error={!!errors.balance}
            helperText={errors.balance}
          />
          <TextField
            label="Tip utilizator"
            select
            name="userType"
            value={formData.userType || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            SelectProps={{ native: true }}
            error={!!errors.userType}
            helperText={errors.userType}
          >
            <option value="Student">Student</option>
            <option value="Profesor">Profesor</option>
            <option value="Admin">Admin</option>
          </TextField>
          <div className="upload-button-container">
            <Button
              component="label"
              variant="outlined"
              color="neutral"
              startDecorator={
                <SvgIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                    />
                  </svg>
                </SvgIcon>
              }
              style={{ width: '100%' }}
            >
              Încarcă o poză a legitimatiei de student/profesor
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </Button>
            {!!errors.idCard && <div className="error">{errors.idCard}</div>}
          </div>
          {preview && (
            <div className="upload-button-container">
              <img className="preview" src={preview} alt="ID Card Preview" />
            </div>
          )}
          <div className="upload-button-container">
            <Button
              component="label"
              variant="outlined"
              color="neutral"
              startDecorator={
                <SvgIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                    />
                  </svg>
                </SvgIcon>
              }
              style={{ width: '100%', marginTop: '16px' }}
            >
              Încarcă o poză de profil
              <VisuallyHiddenInput type="file" onChange={handleProfileFileChange} />
            </Button>
            {!!errors.profilePicture && <div className="error">{errors.profilePicture}</div>}
          </div>
          {profilePreview && (
            <div className="upload-button-container">
              <img className="preview" src={profilePreview} alt="Profile Picture Preview" />
            </div>
          )}
          <div className="buttons">
            <Button
              type="submit"
              variant="contained"
              id="submitButtonEdit"
              fullWidth
              style={{ marginTop: '16px' }}
            >
              Salvează modificările
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}
