import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import TextField from '@mui/material/TextField';
import Button from '@mui/joy/Button';
import 'react-toastify/dist/ReactToastify.css';
import "./Login.css";
import { UserContext } from '../utils/UserContext';  // Import UserContext

const config = require('../../config');

function Verification() {
  const [code, setCode] = useState("");
  const [errors, setErrors] = useState({});
  const [cookies, setCookie] = useCookies([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = location.state || {};
  const { updateUser } = useContext(UserContext);  // Utilizează UserContext

  useEffect(() => {
    if (!userId) {
      toast.error("ID-ul utilizatorului nu a fost găsit. Te rugăm să te loghezi din nou.");
      navigate("/login");
      return;
    }

    const timer = setTimeout(() => {
      toast.error("Sesiunea de logare a expirat.");
      navigate("/login");
    }, 10 * 60 * 1000); // 10 minutes

    return () => clearTimeout(timer);
  }, [navigate, userId]);

  const generateError = (error) =>
    toast.error(error, {
      position: "top-right",
    });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!code) {
      setErrors({ code: "Acest câmp este obligatoriu" });
      return;
    }

    try {
      const { data } = await axios.post(
        `${config.BACKEND_URL}/verify-code`,
        { userId, code },
        { withCredentials: true }
      );

      if (data.token) {
        const userData = {
          userID: data.user.userID,
          userType: data.user.userType,
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          email: data.user.email,
          age: data.user.age,
          gender: data.user.gender,
          faculty: data.user.faculty,
          balance: data.user.balance,
          idCard: data.user.idCard,
          profilePicture: data.user.profilePicture
        };

        setCookie("jwt", data.token, { path: "/", sameSite: 'None', secure: true });
        setCookie("user", userData, { path: "/", sameSite: 'None', secure: true });

        // Actualizează datele utilizatorului
        await updateUser();

        navigate("/");
      }
    } catch (ex) {
      if (ex.response) {
        if (ex.response.status === 403) {
          generateError("Ai introdus codul greșit de 3 ori. Sesiunea de login a fost resetată.");
          navigate("/login");
        } else if (ex.response.data.error) {
          generateError(ex.response.data.error);
        } else {
          generateError("A apărut o eroare la verificare. Încearcă din nou.");
        }
      } else {
        generateError("A apărut o eroare la verificare. Încearcă din nou.");
      }
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;

    if (/^\d*$/.test(value) && value.length <= 6) {
      setCode(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        code: "",
      }));
    }
  };

  return (
    <div className="loginPage">
      <div className="container-login">
        <h2 className="formTitle">Introdu codul de verificare</h2>
        <p style={{ marginTop: '-5px', marginBottom: '15px' }}>
          Codul de verificare a fost trimis către adresa de email introdusă.
        </p>
        <p style={{ marginTop: '-15px', marginBottom: '15px' }}>
          Acesta expiră în 10 minute.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="inputs">
            <TextField
              
              id="outlined-required"
              label="Cod de verificare"
              name="code"
              type="text"
              value={code}
              onChange={handleChange}
              error={!!errors.code}
              helperText={errors.code}
              sx={{ width: '100%' }}
              inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </div>
          <button id="submitButton" type="submit">Verifică</button>
        </form>
      </div>
      
    </div>
  );
}

export default Verification;