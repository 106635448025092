import React from 'react';
import './InfoPage.css';
import developer from '../images/1.webp';
import minicantina from '../images/ugalPhoto.webp';
import cantinastudenteasca from '../images/cantinastudenteasca.webp';
import corpJ from '../images/corpJ.webp';

const InfoPage = () => {
  const locations = [
    {
      name: "Minicantină Universitate",
      address: "str. Domnească, nr. 47",
      image: minicantina,
      mapLink: "https://www.google.com/maps/place/Strada+Domneasc%C4%83+47,+Gala%C8%9Bi/@45.4386397,28.0534352,17z/data=!3m1!4b1!4m6!3m5!1s0x40b6de53908e58a5:0xfa9b95769062b6d8!8m2!3d45.4386397!4d28.0560101!16s%2Fg%2F11ckqm8pst?entry=ttu"
    },
    {
      name: "Corp J - Campusul Științei",
      address: "str. Domnească, nr. 111",
      image: corpJ,
      mapLink: "https://www.google.com/maps/place/Strada+Domneasc%C4%83+111,+Gala%C8%9Bi+800201/@45.4473691,28.0489199,17z/data=!3m1!4b1!4m6!3m5!1s0x40b6de45bca8c5a5:0x4f2de540d4a584f3!8m2!3d45.4473691!4d28.0514948!16s%2Fg%2F11cpn2h4ys?entry=ttu - Campusul Științei, Strada Domnească 111, Galați, România"
    },
    {
      name: "Cantina studențească - Complexul Studențesc \"Al. I. Cuza\"",
      address: "str. Domnească, nr. 155",
      image: cantinastudenteasca,
      mapLink: "https://www.google.com/maps/place/Strada+Domneasc%C4%83+155,+Gala%C8%9Bi+800552/@45.4539499,28.0467391,17z/data=!3m1!4b1!4m6!3m5!1s0x40b6de14340840f9:0x581bddc421f1c4eb!8m2!3d45.4539499!4d28.049314!16s%2Fg%2F11c5nqvg3v?entry=ttu"
    }
  ];

  return (
    <div className="info-page-container">
      <div className="info-page-content">
        <section className="info-developer-section">
          <h3 className="info-category-title">Developer</h3>
          <img src={developer} alt="Developer" className="info-developer-photo" />
          <h2>Daniel-Cristian Olaru</h2>
          <p className="info-centered-text">Student al Facultății de Automatică, Calculatoare, Inginerie Electrică și Electronică din Galați.</p>
        </section>
        <section className="info-canteen-section">
          <h3 className="info-category-title">Locații</h3>
          <div className="info-canteen-locations">
            {locations.map((location, index) => (
              <div key={index} className="info-canteen-location">
                <img src={location.image} alt={location.name} className="info-canteen-image" />
                <h4 className="info-canteen-name">
                  <a href={location.mapLink} className="no-link-style" target="_blank" rel="noopener noreferrer">{location.name}</a>
                </h4>
                <p className="info-canteen-address">
                  <a href={location.mapLink} className="no-link-style" target="_blank" rel="noopener noreferrer">{location.address}</a>
                </p>
              </div>
            ))}
          </div>
        </section>
        <section className="info-contact-section">
          <h3 className="info-category-title">Contact</h3>
          <p className="info-centered-text">Email: dcc@ugal.ro</p>
          <p className="info-centered-text">Telefon: 0336 130 161</p>
        </section>
      </div>
    </div>
  );
};

export default InfoPage;
