import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/joy/Button";
import SvgIcon from '@mui/joy/SvgIcon';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from '@mui/joy';
import { toast } from "react-toastify";
import axios from "axios";
import "./EditProfile.css";
import UseCustomMediaQuery from '../utils/UseCustomMediaQuery';

const config = require('../../config');


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const validateFileExtension = (file) => {
  const validExtensions = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
  return validExtensions.includes(file.type);
};

export default function UpdateProfilePicture({ isOpen, onRequestClose, userID, onUpdate }) {
  const isMobile = UseCustomMediaQuery('(max-width: 600px)');
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '80%' : '30%',
    height: isMobile ? '60%' : '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    borderRadius: 2,
    msOverflowStyle: 'none',  
    scrollbarWidth: 'none',  
  };
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [errors, setErrors] = useState({});

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && !validateFileExtension(file)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        profilePicture: 'Doar fișiere de tip imagine sunt permise (JPEG, PNG, GIF, WEBP)',
      }));
      toast.error('Doar fișiere de tip imagine sunt permise (JPEG, PNG, GIF, WEBP)', { theme: 'light' });
      return;
    }
    setSelectedFile(file);
    setPreview(file ? URL.createObjectURL(file) : null);
    setErrors((prevErrors) => ({
      ...prevErrors,
      profilePicture: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      toast.error("Nu ați încărcat un fișier sau acesta este invalid.", { theme: "light" });
      return;
    }
    const formData = new FormData();
    formData.append("profilePicture", selectedFile);
    try {
      const { data } = await axios.post(`${config.BACKEND_URL}/user/${userID}/updateProfilePicture`, formData, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Poza de profil a fost actualizată cu succes!", { theme: "light" });
      onUpdate(data.profilePicture);
      onRequestClose();
    } catch (error) {
      console.error("Error updating profile picture:", error);
      toast.error("Ceva nu a funcționat, încearcă din nou.", { theme: "light" });
    }
  };

  return (
    <Modal open={isOpen} onClose={onRequestClose}>
      <Box sx={style}>
        <IconButton
          onClick={onRequestClose}
          style={{ position: 'absolute', right: '16px', top: '16px' }}
        >
          <CloseIcon />
        </IconButton>
        <h2 style={{ textAlign: 'center' }}>Actualizează poza de profil</h2>
        <form onSubmit={handleSubmit}>
          <div className="upload-button-container">
            <Button
              component="label"
              variant="outlined"
              color="neutral"
              startDecorator={
                <SvgIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d='M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z'
                    />
                  </svg>
                </SvgIcon>
              }
              style={{ width: '100%' }}
            >
              Încarcă o poză de profil
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </Button>
            {!!errors.profilePicture && <div className="error">{errors.profilePicture}</div>}
          </div>
          {preview && (
            <div className="upload-button-container">
              <img className="preview" src={preview} alt="Profile Picture Preview" />
            </div>
          )}
          <div className="buttons">
            <Button
              type="submit"
              variant="contained"
              id="submitButtonEdit"
              fullWidth
              style={{ marginTop: '16px' }}
            >
              Confirmă
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}
