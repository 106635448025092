import React, { useContext, useState } from 'react';
import './Sidebar.css';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../utils/UserContext'; 
import Recharge from '../users/Recharge';
import Scan from '../navigation/Scan';
import ReportIssue from '../navigation/ReportIssue'; 

const Sidebar = ({ isVisible, toggleSidebar }) => {
  const [isSubOptionsVisible, setSubOptionsVisible] = useState(false);
  const [isRechargeModalOpen, setRechargeModalOpen] = useState(false);
  const [isScanModalOpen, setScanModalOpen] = useState(false);
  const [isReportIssueModalOpen, setReportIssueModalOpen] = useState(false);  
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const toggleSubOptions = () => {
    setSubOptionsVisible(!isSubOptionsVisible);
  };

  const handleOptionClick = (path) => {
    navigate(path);
    if (window.innerWidth <= 600) {
      toggleSidebar();
    }
  };

  const handleRechargeClick = () => {
    setRechargeModalOpen(true);
  };

  const handleScanClick = () => {
    setScanModalOpen(true);
  };

  const handleReportIssueClick = () => {
    setReportIssueModalOpen(true);
  };

  return (
    <div className={`sidebar ${isVisible ? '' : 'hidden'}`}>
      <ul className="sidebar-options">
        <li onClick={() => handleOptionClick('/')}>
          <span className="icon">🏠</span>
          <span>Pagina principala</span>
        </li>
        {user?.userType === 'Admin' && (
          <>
            <li onClick={toggleSubOptions}>
              <span className="icon">⚙️</span>
              <span>Instrumente administrative</span>
            </li>
            {isSubOptionsVisible && (
              <>
                <li className="separator"></li>
                <li className="sub-option" onClick={() => handleOptionClick('/menu')}>
                  <span className="icon">🍕</span>
                  <span>Editeaza meniu</span>
                </li>
                <li className="sub-option" onClick={() => handleOptionClick('/search')}>
                  <span className="icon">👤</span>
                  <span>Cauta utilizatori</span>
                </li>
                <li className="sub-option" onClick={() => handleOptionClick('/pending-registrations')}>
                  <span className="icon">📝</span>
                  <span>Cereri de inregistrare</span>
                </li>
                <li className="sub-option" onClick={() => handleOptionClick('/orders')}>
                  <span className="icon">🍽️</span>
                  <span>Toate comenzile</span>
                </li>
                <li className="sub-option" onClick={handleScanClick}>
                  <span className="icon">🧾</span>
                  <span>Scaneaza o comanda</span>
                </li>
                <li className="separator"></li>
              </>
            )}
          </>
        )}
        <li onClick={() => handleOptionClick('/my-orders')}>
          <span className="icon">🛒</span>
          <span>Comenzile mele</span>
        </li>
        <li onClick={handleRechargeClick}>
          <span className="icon">💳</span>
          <span>Alimentează sold</span>
        </li>
        <li onClick={handleReportIssueClick}>
          <span className="icon">⚠️</span>
          <span>Raporteaza o problema</span>
        </li>
        <li onClick={() => handleOptionClick('/info')}>
          <span className="icon">ℹ️</span>
          <span>Despre noi</span>
        </li>
      </ul>
      <Recharge
        isOpen={isRechargeModalOpen}
        onClose={() => setRechargeModalOpen(false)}
      />
      <Scan 
        isOpen={isScanModalOpen}
        onClose={() => setScanModalOpen(false)}
      />
      <ReportIssue 
        isOpen={isReportIssueModalOpen}
        onClose={() => setReportIssueModalOpen(false)}
      />
    </div>
  );
};

export default Sidebar;
