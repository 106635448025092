import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './ForgotPassword.css';

const config = require('../../config');


function ForgotPassword() {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${config.BACKEND_URL}/forgot-password`, { email });
      toast.success('Dacă mail-ul introdus este ascociat unui cont înregistrat, vei primi un email cu instrucțiuni pentru resetarea parolei', { theme: 'light' });
    } catch (error) {
      toast.success('Dacă mail-ul introdus este ascociat unui cont înregistrat, vei primi un email cu instrucțiuni pentru resetarea parolei', { theme: 'light' });
    }
  };

  return (
    <div className="forgotPasswordPage">
      <div className="container-forgot">
        <h2 className="formTitle">Recuperare parolă</h2>
        <form onSubmit={handleSubmit}>
          <div className="inputs">
            <TextField
              required
              id="outlined-required"
              label="Email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ width: '100%' }}
            />
          </div>
          <button id="submitButton" type="submit">Trimite</button>
          <div className="displayLinks">
            <p>Te-ai răzgândit? <Link to="/login">Loghează-te</Link></p>
          </div>
        </form>
        
      </div>
    </div>
  );
}

export default ForgotPassword;
