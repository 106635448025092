import React, { useEffect, useRef } from 'react';
import './Scan.css';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ScanModal = ({ isOpen, onClose }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile && isOpen) {
      toast.error('Această opțiune nu este disponibilă pentru dispozitivele mobile.');
      onClose();
      return;
    }

    if (!isOpen) return;

    const handleScan = (event) => {
      if (event.key === 'Enter') {
        const scannedData = event.target.value.trim();
        if (scannedData.startsWith('http')) {
          window.open(scannedData, '_blank');
        }
        event.target.value = ''; 
      }
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.focus();
      inputElement.addEventListener('keydown', handleScan);
      return () => {
        inputElement.removeEventListener('keydown', handleScan);
      };
    }
  }, [isOpen, onClose]);

  const handleHover = () => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.focus();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content-scan">
        <h2>Scanează o comandă</h2>
        <div 
          className="scan-area" 
          onMouseEnter={handleHover}
        >
          Pentru a scana codul QR al unei comenzi, plasează cursorul aici și scanează codul.
        </div>
        <input ref={inputRef} type="text" style={{ position: 'absolute', opacity: 0, pointerEvents: 'none' }} />
        <Button variant="contained" style={{textTransform:"none", fontFamily:'Outfit, sans-serif'}} color="primary" onClick={onClose}>Închide</Button>
      </div>
    </div>
  );
};

export default ScanModal;
