import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, TextField, Checkbox, FormControlLabel, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import './EditMenuItem.css';
import { styled } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';
import UseCustomMediaQuery from '../utils/UseCustomMediaQuery';  
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const config = require('../../config');

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function EditMenuItem({ item, onClose, onUpdate }) {
  const isMobile = UseCustomMediaQuery('(max-width: 600px)');
  const [formData, setFormData] = useState({
    name: item.name,
    price: item.price,
    available: item.available,
    imageUrl: item.imageUrl,
    category: item.category,
    description: item.description,
    quantity: item.quantity,
    unit: item.unit,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setPreview(`${config.BACKEND_URL}${item.imageUrl}`);
  }, [item.imageUrl]);

  const validateFileExtension = (file) => {
    const validExtensions = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    return validExtensions.includes(file.type);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    if (name === 'name' && value.length > 50) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Acest câmp nu poate avea mai mult de 50 de caractere',
      }));
      return;
    } else if (name === 'description' && value.length > 200) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Acest câmp nu poate avea mai mult de 200 de caractere',
      }));
      return;
    } else if (name === 'price' || name === 'quantity') {
      if (value > 999999999999999) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Valoarea introdusă este prea mare',
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '',
        }));
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleKeyPress = (e) => {
    const { name, value } = e.target;
    if ((name === 'price' || name === 'quantity') && !/^[0-9]*\.?[0-9]*$/.test(value + e.key)) {
      e.preventDefault();
    }
  };
  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && !validateFileExtension(file)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        menuItemImage: 'Doar fișiere de tip imagine sunt permise (JPEG, PNG, GIF, WEBP)',
      }));
      toast.error('Doar fișiere de tip imagine sunt permise (JPEG, PNG, GIF, WEBP)', { theme: 'light' });
      return;
    }
    setSelectedFile(file);
    setPreview(file ? URL.createObjectURL(file) : null);
    setErrors((prevErrors) => ({
      ...prevErrors,
      menuItemImage: '',
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.name) newErrors.name = 'Acest câmp este obligatoriu';
    if (!formData.price) newErrors.price = 'Acest câmp este obligatoriu';
    if (!formData.category) newErrors.category = 'Acest câmp este obligatoriu';
    if (!formData.quantity) newErrors.quantity = 'Acest câmp este obligatoriu';
    if (!formData.description) newErrors.description = 'Acest câmp este obligatoriu';
    if (!selectedFile && !formData.imageUrl) newErrors.menuItemImage = 'Nu ați selectat nicio imagine';

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toast.error('Există erori în formular. Vă rugăm să le corectați înainte de a trimite.', { theme: 'light' });
      return;
    }

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });
    if (selectedFile) {
      data.append('menuItemImage', selectedFile);
    }

    try {
      const response = await axios.put(`${config.BACKEND_URL}/menu/${item._id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      onUpdate(response.data);
      toast.success('Produsul a fost actualizat cu succes!');
      onClose();
    } catch (error) {
      console.error('Error updating menu item', error);
      toast.error('Eroare la actualizarea produsului', { theme: 'light' });
    }
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="edit-dialog-title">
      
      <div className={`edit-menu-item-container ${isMobile ? 'mobile' : ''}`}>
        <IconButton
          className="close-button"
          style={{ position: 'absolute' }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        
        <DialogContent className="dialog-scroll-content">
          <h2 className="edit-title">Editează produsul</h2>
          <form onSubmit={handleSubmit}>
            <TextField
              margin="dense"
              label="Nume"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              margin="dense"
              label="Preț"
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              fullWidth
              onWheel={(e) => e.target.blur()}
              error={!!errors.price}
              helperText={errors.price}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="available"
                  checked={formData.available}
                  onChange={handleCheckboxChange}
                />
              }
              label="Produsul e în stoc?"
            />
            <TextField
              select
              id="outlined-required"
              label="Categorie"
              name="category"
              value={formData.category}
              onChange={handleChange}
              SelectProps={{ native: true }}
              fullWidth
              
              error={!!errors.category}
              helperText={errors.category}
            >
              <option value=""></option>
              <option value="Ciorbe și supe">Ciorbe și supe</option>
              <option value="Garnituri">Garnituri</option>
              <option value="Preparate carne">Preparate carne</option>
              <option value="Salate/sosuri">Salate/sosuri</option>
              <option value="Pâine">Pâine</option>
              <option value="Desert">Desert</option>
              <option value="Meniul zilei">Meniul zilei</option>
            </TextField>
            <TextField
              margin="dense"
              label="Ingrediente"
              type="text"
              name="description"
              value={formData.description}
              onChange={handleChange}
              fullWidth
              error={!!errors.description}
              helperText={errors.description}
            />
            <TextField
              margin="dense"
              label="Cantitate"
              type="number"
              name="quantity"
              value={formData.quantity}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              onWheel={(e) => e.target.blur()}
              fullWidth
              error={!!errors.quantity}
              helperText={errors.quantity}
            />
            <TextField
              margin="dense"
              label="Unitate de măsură"
              select
              name="unit"
              value={formData.unit}
              onChange={handleChange}
              SelectProps={{ native: true }}
              fullWidth
              required
            >
              <option value="ml">ml</option>
              <option value="g">g</option>
              <option value="buc">buc</option>
            </TextField>
            <div className="upload-button-section">
              <Button
                component="label"
                variant="outlined"
                startIcon={
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </SvgIcon>
                }
                fullWidth
              >
                Încarcă o poză cu produsul
                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
              </Button>
              {!!errors.menuItemImage && <div className="error">{errors.menuItemImage}</div>}
              {preview && <img src={preview} alt="Preview" style={{ width: '300px', height: 'auto' }} />}
            </div>
            <DialogActions className="form-actions-section">
              <Button style={{textTransform:"none", fontFamily:'Outfit, sans-serif'}} type="submit" color="primary" variant="contained">
                Salvează
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default EditMenuItem;
