import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from './UserContext';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import MainLayout from '../navigation/MainLayout';

const RouteWrapper = ({ component: Component, ...rest }) => {
  const { user, updateUser } = useContext(UserContext);
  const [cookies] = useCookies(['user']);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;

    const verifyAndUpdateUser = async () => {
      if (!cookies.jwt) {
        if (isMounted) {
          navigate("/login");
        }
        return;
      }

      if (isMounted) {
        await updateUser();
      }
    };

    verifyAndUpdateUser();

    return () => {
      isMounted = false; 
    };
  }, [location.pathname]);

  return (
    <MainLayout>
      <Component {...rest} />
    </MainLayout>
  );
};

export default RouteWrapper;
