import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import './SearchUsers.css';

const config = require('../../config');


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  textAlign: 'center' 
}));

const CenteredTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center', 
  
  justifyContent: 'center', 
  alignItems: 'center' 
}));

export default function SearchUsers() {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(`${config.BACKEND_URL}/search?name=${query}`, {
        withCredentials: true,
      });
      setResults(data);
      if (data.length === 0) {
        toast.error("Numele introdus nu aparține niciunui utilizator.", { theme: "light" });
      }
    } catch (err) {
      console.error("Error searching users:", err);
      toast.error("Introduceți numele pe care doriți să-l căutați", { theme: "light" });
    }
  };

  return (
    <div className="search-page">
      <h1 className="category-title">Caută utilizatori</h1>
      <form onSubmit={handleSearch} className="search-form">
        <TextField
          className="search-input"
          variant="outlined"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Introdu numele pe care doresti să-l cauți"
        />
        <Button style={{textTransform:"none", fontFamily:'Outfit, sans-serif'}} type="submit" variant="contained" color="primary">Caută</Button>
      </form>
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>UserID</StyledTableCell>
              <StyledTableCell>Poză</StyledTableCell>
              <StyledTableCell>Nume</StyledTableCell>
              <StyledTableCell>Facultate</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Tip utilizator</StyledTableCell>
              <StyledTableCell>Acțiuni</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((user) => (
              <TableRow key={user._id}>
                <CenteredTableCell>{user.userID}</CenteredTableCell>
                <CenteredTableCell>
                  <img src={`${config.BACKEND_URL}/uploads/profilePictures/${user.profilePicture || '/default-profile.png'}`} alt={user.firstName} className="search-results-image" />
                </CenteredTableCell>
                <CenteredTableCell>{user.firstName} {user.lastName}</CenteredTableCell>
                <CenteredTableCell>{user.faculty || 'N/A'}</CenteredTableCell>
                <CenteredTableCell>{user.email}</CenteredTableCell>
                <CenteredTableCell>{user.userType || 'N/A'}</CenteredTableCell>
                <CenteredTableCell className="table-actions-cell">
                  <Button style={{textTransform:"none", fontFamily:'Outfit, sans-serif'}} variant="contained" color="primary" onClick={() => navigate(`/user/${user.userID}`)}>
                    Vizualizează profil
                  </Button>
                </CenteredTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
    </div>
  );
}
