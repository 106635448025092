import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/joy';
import Button from '@mui/joy/Button';
import SvgIcon from '@mui/joy/SvgIcon';
import './CreateMenuItem.css';
import IconButton from '@mui/joy/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const config = require('../../config');

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function CreateMenuItem({ onClose, onCreate }) {
  const [formData, setFormData] = useState({
    name: '',
    price: '',
    available: true,
    category: '',
    description: '', 
    quantity: '', 
    unit: 'g' 
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [errors, setErrors] = useState({});

  const validateFileExtension = (file) => {
    const validExtensions = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    return validExtensions.includes(file.type);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    if (name === 'name' && value.length > 50) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Acest câmp nu poate avea mai mult de 50 de caractere',
      }));
      return;
    } else if (name === 'description' && value.length > 200) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Acest câmp nu poate avea mai mult de 200 de caractere',
      }));
      return;
    } else if (name === 'price' || name === 'quantity') {
      if (value > 999999999999999) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Valoarea introdusă este prea mare',
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '',
        }));
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleKeyPress = (e) => {
    const { name, value } = e.target;
    if ((name === 'price' || name === 'quantity') && !/^[0-9]*\.?[0-9]*$/.test(value + e.key)) {
      e.preventDefault();
    }
  };
  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && !validateFileExtension(file)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        menuItemImage: 'Doar fișiere de tip imagine sunt permise (JPEG, PNG, GIF, WEBP)',
      }));
      toast.error('Doar fișiere de tip imagine sunt permise (JPEG, PNG, GIF, WEBP)', { theme: 'light' });
      return;
    }
    setSelectedFile(file);
    setPreview(file ? URL.createObjectURL(file) : null);
    setErrors((prevErrors) => ({
      ...prevErrors,
      menuItemImage: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.name) newErrors.name = 'Acest câmp este obligatoriu';
    if (!formData.price) newErrors.price = 'Acest câmp este obligatoriu';
    if (!formData.category) newErrors.category = 'Acest câmp este obligatoriu';
    if (!formData.description) newErrors.description = 'Acest câmp este obligatoriu';
    if (!formData.quantity) newErrors.quantity = 'Acest câmp este obligatoriu';
    if (!selectedFile) newErrors.menuItemImage = 'Nu ați selectat nicio imagine';

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toast.error('Există erori în formular. Vă rugăm să le corectați înainte de a trimite.', { theme: 'light' });
      return;
    }

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });
    if (selectedFile) {
      data.append('menuItemImage', selectedFile);
    }

    try {
      const response = await axios.post(`${config.BACKEND_URL}/menu`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      onCreate(response.data);
      toast.success('Produsul a fost creat cu succes!');
      onClose();
    } catch (error) {
      console.error('Error creating menu item', error);
      toast.error('Eroare la crearea produsului', { theme: 'light' });
    }
  };

  return (
    <div className="create-menu-item-container">
     
      <IconButton className="close-button" style={{ position: 'absolute' }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <div className="scrollable-content">
        <h2>Adaugă produs</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            id="outlined-required"
            label="Nume"
            type="text"
            name="name"
            style={{ marginTop: '10px' }}
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            id="outlined-required"
            label="Preț"
            type="number"
            name="price"
            value={formData.price}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            error={!!errors.price}
            helperText={errors.price}
            onWheel={(e) => e.target.blur()}
          />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={formData.available} onChange={handleCheckboxChange} name="available" />}
              label="Produsul e în stoc?"
            />
          </FormGroup>
          <TextField
            select
            id="outlined-required"
            label="Categorie"
            name="category"
            value={formData.category}
            onChange={handleChange}
            SelectProps={{ native: true }}
            error={!!errors.category}
            helperText={errors.category}
          >
            <option value=""></option>
            <option value="Ciorbe și supe">Ciorbe și supe</option>
            <option value="Garnituri">Garnituri</option>
            <option value="Preparate carne">Preparate carne</option>
            <option value="Salate/sosuri">Salate/sosuri</option>
            <option value="Pâine">Pâine</option>
            <option value="Desert">Desert</option>
            <option value="Meniul zilei">Meniul zilei</option>
          </TextField>
          <TextField
            id="outlined-multiline-flexible"
            label="Ingrediente"
            name="description"
            multiline
            maxRows={10}
            value={formData.description}
            onChange={handleChange}
            error={!!errors.description}
            helperText={errors.description}
          />
          <TextField
            id="outlined-required"
            label="Cantitate"
            type="number"
            name="quantity"
            value={formData.quantity}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            error={!!errors.quantity}
            helperText={errors.quantity}
            onWheel={(e) => e.target.blur()}
          />
          <TextField
            select
            id="outlined-required"
            label="Unitate de măsură"
            name="unit"
            value={formData.unit}
            onChange={handleChange}
            SelectProps={{ native: true }}
          >
            <option value="g">g</option>
            <option value="ml">ml</option>
            <option value="buc">buc</option>
          </TextField>
          <div className="upload-button-container">
            <Button
              component="label"
              variant="outlined"
              color="neutral"
              startDecorator={
                <SvgIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                    />
                  </svg>
                </SvgIcon>
              }
            >
              Încarcă o poză cu produsul
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </Button>
            {!!errors.menuItemImage && <div className="error">{errors.menuItemImage}</div>}
            {preview && <img src={preview} alt="Preview" style={{ width: '300px', height: 'auto' }} />}
          </div>
          <div className="form-actions">
            <button type="submit">Adaugă</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateMenuItem;
