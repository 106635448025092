import React from "react";
import { useNavigate } from "react-router-dom";
import './PendingApproval.css';

export default function PendingApproval() {
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="pendingApprovalPage">
      <div className="container-pending">
        <h2 className="formTitle">Cerere de înregistrare trimisă</h2>
        <p>Cererea ta a fost trimisă și va fi revizuită de către un administrator.</p>
        <p>Vei primi un răspuns pe email în cel mai scurt timp.</p>
        <button id="submitButton" onClick={handleGoToLogin}>Către pagina principală</button>
      </div>
    </div>
  );
}
