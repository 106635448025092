import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, InputLabel, FormControl, TextField
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import './OrdersList.css';
import UseCustomMediaQuery from '../utils/UseCustomMediaQuery';

const config = require('../../config');


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  textAlign: 'center' 
}));

const CenteredTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center' 
}));

function OrdersList() {
  const isMobile = UseCustomMediaQuery('(max-width: 600px)');
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    async function fetchOrdersAndUsers() {
      try {
        const ordersResponse = await axios.get(`${config.BACKEND_URL}/orders`);
        const usersResponse = await axios.get(`${config.BACKEND_URL}/active-users`);

        const usersMap = usersResponse.data.reduce((acc, user) => {
          acc[user.userID] = user;
          return acc;
        }, {});

        const ordersWithUserDetails = ordersResponse.data.map((order) => ({
          ...order,
          user: usersMap[order.user] || null,
        }));

        setOrders(ordersWithUserDetails);
        setFilteredOrders(ordersWithUserDetails);
        setUsers(usersResponse.data);
      } catch (error) {
        console.error('Error fetching orders or users', error);
      }
    }

    fetchOrdersAndUsers();

    
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  useEffect(() => {
    const handleScan = (event) => {
      if (event.key === 'Enter') {
        const scannedData = event.target.value.trim();
        if (scannedData.startsWith('http')) {
          window.open(scannedData, '_blank');
        }
        event.target.value = ''; 
      }
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('keydown', handleScan);
      return () => {
        inputElement.removeEventListener('keydown', handleScan);
      };
    }
  }, [inputRef]);

  const handleImageHover = () => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.focus();
    }
  };

  const handleStatusChange = (e) => {
    const status = e.target.value;
    setSelectedStatus(status);
    filterOrders(status, selectedLocation, selectedPaymentMethod, selectedUser);
  };

  const handleLocationChange = (e) => {
    const location = e.target.value;
    setSelectedLocation(location);
    filterOrders(selectedStatus, location, selectedPaymentMethod, selectedUser);
  };

  const handlePaymentMethodChange = (e) => {
    const paymentMethod = e.target.value;
    setSelectedPaymentMethod(paymentMethod);
    filterOrders(selectedStatus, selectedLocation, paymentMethod, selectedUser);
  };

  const handleUserChange = (event, value) => {
    setSelectedUser(value);
    filterOrders(selectedStatus, selectedLocation, selectedPaymentMethod, value);
  };

  const filterOrders = (status, location, paymentMethod, user) => {
    let filtered = orders;
    if (status) {
      filtered = filtered.filter(order => order.status === status);
    }
    if (location) {
      filtered = filtered.filter(order => order.location === location);
    }
    if (paymentMethod) {
      filtered = filtered.filter(order => order.paymentMethod === paymentMethod);
    }
    if (user) {
      filtered = filtered.filter(order => order.user && order.user.userID === user.userID);
    }
    setFilteredOrders(filtered);
  };

  const getLocationDisplayName = (location) => {
    switch(location) {
      case 'Universitate':
        return 'Minicantină Universitate';
      case 'Corp J':
        return 'Corp J - Campusul Științei';
      case 'Campusul studentesc Al. I. Cuza':
        return 'Cantina studențească - Complexul Studențesc "Al. I. Cuza"';
      default:
        return location;
    }
  };

  const getStatusDisplayName = (status) => {
    switch(status) {
      case 'new':
        return 'Comandă nouă';
      case 'in progress':
        return 'Comandă în curs de pregătire';
      case 'finished':
        return 'Comandă finalizată';
      case 'delivered':
        return 'Comandă ridicată';
      default:
        return status;
    }
  };

  const getPaymentTypeDisplayName = (paymentType) => {
    switch(paymentType) {
      case 'cash':
        return 'Cash';
      case 'balance':
        return 'Sold';
      default:
        return paymentType;
    }
  };

  return (
    <div className="order-list-page">
      <h2 className="category-title">Toate comenzile</h2>
      <div className="search-form">
        <FormControl className="filter-select">
          <InputLabel>Status</InputLabel>
          <Select style={{width: isMobile ? '100%' : '350px'}} value={selectedStatus} onChange={handleStatusChange} label="Status">
            <MenuItem value="">Toate comenzile</MenuItem>
            <MenuItem value="new">Comandă nouă</MenuItem>
            <MenuItem value="in progress">Comandă în curs de pregătire</MenuItem>
            <MenuItem value="finished">Comandă finalizată</MenuItem>
            <MenuItem value="delivered">Comandă ridicată</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="filter-select">
          <InputLabel>Locație</InputLabel>
          <Select style={{width: isMobile ? '100%' : '350px'}} value={selectedLocation} onChange={handleLocationChange} label="Location">
            <MenuItem value="">Toate locațiile</MenuItem>
            <MenuItem value="Universitate">Minicantină Universitate</MenuItem>
            <MenuItem value="Corp J">Corp J - Campusul Științei</MenuItem>
            <MenuItem value="Campusul studentesc Al. I. Cuza">Cantina studențească</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="filter-select">
          <InputLabel>Metodă de plată</InputLabel>
          <Select style={{width: isMobile ? '100%' : '350px'}} value={selectedPaymentMethod} onChange={handlePaymentMethodChange} label="Metodă de plată">
            <MenuItem value="">Toate metodele de plată</MenuItem>
            <MenuItem value="cash">Cash</MenuItem>
            <MenuItem value="balance">Sold</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="filter-select">
          <Autocomplete
            options={users}
            getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
            onChange={handleUserChange}
            renderInput={(params) => <TextField {...params} label="Nume client" />}
            style={{width: isMobile ? '100%' : '350px'}}
          />
        </FormControl>
      </div>
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Nume client</StyledTableCell>
              <StyledTableCell>Nr. Produse</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Locație</StyledTableCell>
              <StyledTableCell>Metodă de plată</StyledTableCell>
              <StyledTableCell>Total de plată</StyledTableCell>
              <StyledTableCell>Acțiuni</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOrders.map((order) => (
              <TableRow key={order._id}>
                <CenteredTableCell>{order.orderID}</CenteredTableCell>
                <CenteredTableCell>{order.user ? `${order.user.firstName} ${order.user.lastName}` : 'N/A'}</CenteredTableCell>
                <CenteredTableCell>{order.items.length}</CenteredTableCell>
                <CenteredTableCell>{getStatusDisplayName(order.status)}</CenteredTableCell>
                <CenteredTableCell>{getLocationDisplayName(order.location)}</CenteredTableCell>
                <CenteredTableCell>{getPaymentTypeDisplayName(order.paymentMethod)}</CenteredTableCell>
                <CenteredTableCell>{(order.totalPrice).toFixed(2)} RON</CenteredTableCell>
                <CenteredTableCell className="table-actions-cell">
                  <Button style={{textTransform:"none", fontFamily:'Outfit, sans-serif'}} variant="contained" color="primary" onClick={() => navigate(`/order/${order._id}`)}>
                    Vizualizează comanda
                  </Button>
                </CenteredTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <input ref={inputRef} type="text" className="hidden-input" />
    </div>
  );
}

export default OrdersList;
