import React, { useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../utils/UserContext'; 
import { toast } from 'react-toastify';

const config = require('../../config');


const Success = () => {
  const { updateUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('session_id');
  const userId = queryParams.get('user_id');
  const isProcessed = useRef(false); 

  useEffect(() => {
    if (isProcessed.current) return; 

    if (sessionId && userId) {
      isProcessed.current = true; 
      axios.post(`${config.BACKEND_URL}/update-balance-after-payment`, { sessionId, userId })
        .then(response => {
          if (response.data.success) {
            
            updateUser();
            toast.success(`Contul tău a fost alimentat cu ${response.data.amount} lei.`);
            
              navigate('/');
           
          }
        })
        .catch(error => {
          console.error('Error updating balance', error);
        });
    }
  }, [sessionId, userId, updateUser, navigate]);

  return null;
};

export default Success;
