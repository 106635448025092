import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import './MainLayout.css';

const MainLayout = ({ children }) => {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setSidebarVisible(false);
      } else {
        setSidebarVisible(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    
    const transitionTimeout = setTimeout(() => {
      setInitialLoad(false);
    }, 100); 

    return () => {
      window.removeEventListener('resize', handleResize); 
      clearTimeout(transitionTimeout); 
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  return (
    <div className={`main-layout ${initialLoad ? 'no-transition' : ''}`}> 
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="layout-body">
        <Sidebar isVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
        <div className={`content ${isSidebarVisible ? '' : 'sidebar-hidden'}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
