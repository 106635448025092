import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import './RegistrationRequest.css';
import UseCustomMediaQuery from '../utils/UseCustomMediaQuery'; 

const config = require('../../config');


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  textAlign: 'center',
  width: '5%',
}));

const CenteredTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '70%', 
}));

export default function RegistrationRequest() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const isMobile = UseCustomMediaQuery('(max-width: 600px)');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const { data } = await axios.get(`${config.BACKEND_URL}/user/${id}`);
        if (data.status === 'active') {
          navigate("/pending-registrations");
          toast.error("Contul asociat cererii accesate este activ!", { theme: "light" });
        } else {
          setUserDetails(data);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        navigate("/pending-registrations",);
        setTimeout(() => {
        toast.error("Cererea accesată nu a fost găsită!", { theme: "light" });
        },1000);
      }
    };

    fetchUserDetails();
  }, [id, navigate]);

  const handleApprove = async () => {
    try {
      await axios.post(`${config.BACKEND_URL}/registrations/approve/${id}`);
      toast.success("Utilizatorul a fost acceptat cu succes!", { theme: "light" });
      navigate("/pending-registrations");
    } catch (error) {
      console.error("Error approving user:", error);
      toast.error("A apărut o eroare la acceptarea utilizatorului.", { theme: "light" });
    }
  };

  const handleReject = async () => {
    try {
      await axios.delete(`${config.BACKEND_URL}/registrations/reject/${id}`);
      toast.success("Utilizatorul a fost respins cu succes!", { theme: "light" });
      navigate("/pending-registrations");
    } catch (error) {
      console.error("Error rejecting user:", error);
      toast.error("A apărut o eroare la respingerea utilizatorului.", { theme: "light" });
    }
  };

  if (!userDetails) {
    return <p>Loading...</p>;
  }

  return (
    <div className="registration-request-page">
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableBody>
            <TableRow>
              <StyledTableCell>Nume complet</StyledTableCell>
              <CenteredTableCell>{userDetails.firstName} {userDetails.lastName}</CenteredTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Email</StyledTableCell>
              <CenteredTableCell>{userDetails.email}</CenteredTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Facultate</StyledTableCell>
              <CenteredTableCell>{userDetails.faculty}</CenteredTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Gen</StyledTableCell>
              <CenteredTableCell>{userDetails.gender}</CenteredTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Vârstă</StyledTableCell>
              <CenteredTableCell>{userDetails.age}</CenteredTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Legitimație</StyledTableCell>
              <CenteredTableCell>
                <img
                  width={isMobile ? "300" : "550"}
                  height="550"
                  src={`${config.BACKEND_URL}/uploads/badges/${userDetails.idCard}`}
                  alt="ID Card"
                  className="id-card-image"
                />
              </CenteredTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Acțiuni</StyledTableCell>
              <CenteredTableCell className="actions-container">
                <Button
                  variant="contained"
                  className="action-button"
                  style={{
                    marginBottom: '10px',
                    textTransform: 'none',
                    fontFamily: 'Outfit, sans-serif',
                    backgroundColor: '#2E7D3D',
                    marginRight: '10px',
                    width: '100px',
                  }}
                  onClick={handleApprove}
                >
                  Acceptă
                </Button>
                <Button
                  variant="contained"
                  className="action-button"
                  style={{
                    marginBottom: '10px',
                    textTransform: 'none',
                    fontFamily: 'Outfit, sans-serif',
                    backgroundColor: '#D32F2F',
                    width: '100px',
                  }}
                  onClick={handleReject}
                >
                  Respinge
                </Button>
              </CenteredTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
