import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Register from "./components/auth/Register";
import Verification from "./components/auth/Verification";
import Login from "./components/auth/Login";
import UserProfile from "./components/users/UserProfile";
import SearchUsers from "./components/users/SearchUsers";
import PendingRegistrations from "./components/auth/PendingRegistrations";
import RegistrationRequest from "./components/auth/RegistrationRequest";
import PendingApproval from "./components/auth/PendingApproval";
import MenuItems from "./components/menu/MenuItems";
import CreateOrder from "./components/orders/CreateOrder";
import OrdersList from "./components/orders/OrdersList";
import OrderDetails from "./components/orders/OrderDetails";
import CreateMenuItem from "./components/menu/CreateMenuItem";
import 'react-toastify/dist/ReactToastify.css';
import MyOrders from "./components/orders/MyOrders";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import { UserProvider } from "./components/utils/UserContext";
import RouteWrapper from './components/utils/RouteWrapper';
import {useState} from 'react';
import ReportIssue from "./components/navigation/ReportIssue";
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import { useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import config from './config';
import Success from "./components/users/Success";
import InfoPage from "./components/navigation/InfoPage";
import { Navigate } from "react-router-dom";



export default function App() {

  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      @media (max-width: 600px) {
        .Toastify__toast-container {
          width: 90% !important;
          left: 5% !important;
          right: 5% !important;
          top: 10px !important;
          
          
        }
        .Toastify__toast {
          max-width: 100% !important;
          margin-bottom: 10px !important;
        }
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);



  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify-code" element={<Verification />} />
          <Route path="/user/:id" element={<RouteWrapper component={UserProfile} />} />
          <Route path="/search" element={<RouteWrapper component={SearchUsers} />} />
          <Route path="/pending-registrations" element={<RouteWrapper component={PendingRegistrations} />} />
          <Route path="/registration/:id" element={<RouteWrapper component={RegistrationRequest} />} />
          <Route path="/menu" element={<RouteWrapper component={MenuItems} />} />
          <Route path="/create-order" element={<RouteWrapper component={CreateOrder} />} />
          
          <Route path="/orders" element={<RouteWrapper component={OrdersList} />} />
          <Route path="/order/:id" element={<RouteWrapper component={OrderDetails} />} />
          <Route path="/create-menu-item" element={<RouteWrapper component={CreateMenuItem} />} />
          <Route path="/info" element={<RouteWrapper component={InfoPage} />} />
          <Route path="/success" element={<Success />} />
          <Route path="/" element={<RouteWrapper component={CreateOrder} />} />
          <Route path="/pending-approval" element={<PendingApproval />} />
          <Route path="/my-orders" element={<RouteWrapper component={MyOrders} />} />
          <Route path="/report" element={<RouteWrapper component={ReportIssue} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/" />} />
          
          
        </Routes>
        <ToastContainer />
      </UserProvider>
    </Router>
  );
}
