import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, FormControl, Select, MenuItem, InputLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import './PendingRegistrations.css';
import UseCustomMediaQuery from '../utils/UseCustomMediaQuery';

const config = require('../../config');


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  textAlign: 'center' 
}));

const CenteredTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center', 
  justifyContent: 'center', 
  alignItems: 'center' 
}));

export default function PendingRegistrations() {
  const [pendingRegistrations, setPendingRegistrations] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState('');
  const [selectedOccupation, setSelectedOccupation] = useState('');
  const navigate = useNavigate();
  const isMobile = UseCustomMediaQuery('(max-width: 600px)');

  useEffect(() => {
    const fetchPendingRegistrations = async () => {
      try {
        const { data } = await axios.get(`${config.BACKEND_URL}/registrations/pending`);
        setPendingRegistrations(data);
      } catch (error) {
        console.error("Error fetching pending registrations:", error);
        toast.error("Actualizarea cererilor de înregistrare a eșuat", { theme: "light" });
      }
    };

    fetchPendingRegistrations();
  }, []);

  const handleFacultyChange = (e) => {
    setSelectedFaculty(e.target.value);
    filterRegistrations(e.target.value, selectedOccupation);
  };

  const handleOccupationChange = (e) => {
    setSelectedOccupation(e.target.value);
    filterRegistrations(selectedFaculty, e.target.value);
  };

  const filterRegistrations = (faculty, occupation) => {
    let filtered = pendingRegistrations;
    if (faculty) {
      filtered = filtered.filter(user => user.faculty === faculty);
    }
    if (occupation) {
      filtered = filtered.filter(user => user.userType === occupation);
    }
    setPendingRegistrations(filtered);
  };

  return (
    <div className="pending-registrations-page">
      <h1 style={{fontSize: isMobile ? '23px' : '36px'}} className="category-title">Cereri de înregistrare</h1>
      <div className="filter-form">
        <FormControl className="filter-select">
          <InputLabel>Facultate</InputLabel>
          <Select style={{width: isMobile ? '100%' : '350px'}} value={selectedFaculty} onChange={handleFacultyChange} label="Facultate">
            <MenuItem value="">Toate facultățile</MenuItem>
            <MenuItem value="Facultatea de Inginerie">Facultatea de Inginerie</MenuItem>
            <MenuItem value="Facultatea de Arhitectură Navală">Facultatea de Arhitectură Navală</MenuItem>
            <MenuItem value="Facultatea de Știința și Ingineria Alimentelor">Facultatea de Știința și Ingineria Alimentelor</MenuItem>
            <MenuItem value="Facultatea de Automatică, Calculatoare, Inginerie Electrică și Electronică">Facultatea de Automatică, Calculatoare, Inginerie Electrică și Electronică</MenuItem>
            <MenuItem value="Facultatea de Educație Fizică și Sport">Facultatea de Educație Fizică și Sport</MenuItem>
            <MenuItem value="Facultatea de Litere">Facultatea de Litere</MenuItem>
            <MenuItem value="Facultatea de Științe și Mediu">Facultatea de Științe și Mediu</MenuItem>
            <MenuItem value="Facultatea de Istorie, Filosofie și Teologie">Facultatea de Istorie, Filosofie și Teologie</MenuItem>
            <MenuItem value="Facultatea de Economie și Administrarea Afacerilor">Facultatea de Economie și Administrarea Afacerilor</MenuItem>
            <MenuItem value="Facultatea de Drept și Științe Administrative">Facultatea de Drept și Științe Administrative</MenuItem>
            <MenuItem value="Facultatea de Medicină și Farmacie">Facultatea de Medicină și Farmacie</MenuItem>
            <MenuItem value="Facultatea de Arte">Facultatea de Arte</MenuItem>
            <MenuItem value="Facultatea Transfrontalieră">Facultatea Transfrontalieră</MenuItem>
        
            
          </Select>
        </FormControl>
        <FormControl className="filter-select">
          <InputLabel>Ocupație</InputLabel>
          <Select style={{width: isMobile ? '100%' : '350px'}} value={selectedOccupation} onChange={handleOccupationChange} label="Ocupație">
            <MenuItem value="">Toate ocupațiile</MenuItem>
            <MenuItem value="Student">Student</MenuItem>
            <MenuItem value="Profesor">Profesor</MenuItem>
            
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Nume complet</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Facultate</StyledTableCell>
              <StyledTableCell>Ocupație</StyledTableCell>
              <StyledTableCell>Acțiuni</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingRegistrations.map((user) => (
              <TableRow key={user.userID}>
                <CenteredTableCell>{user.firstName} {user.lastName}</CenteredTableCell>
                <CenteredTableCell>{user.email}</CenteredTableCell>
                <CenteredTableCell>{user.faculty}</CenteredTableCell>
                <CenteredTableCell>{user.userType}</CenteredTableCell>
                <CenteredTableCell className="table-actions-cell">
                  <Button style={{textTransform:"none", fontFamily:'Outfit, sans-serif'}} variant="contained" color="primary" onClick={() => navigate(`/registration/${user.userID}`)}>
                    Vizualizează cererea
                  </Button>
                </CenteredTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
    </div>
  );
}
