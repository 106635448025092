import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import TextField from '@mui/material/TextField';
import './ResetPassword.css';
import 'react-toastify/dist/ReactToastify.css';

const config = require('../../config');


function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const { token } = useParams();
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,}$/;
    return regex.test(password);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    if (name === 'password') {
      if (!validatePassword(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: 'Parola trebuie să aibă minim 6 caractere, o literă mare și o cifră',
        }));
      }
    } else if (name === 'confirmPassword') {
      if (value !== password) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: 'Parolele nu se potrivesc',
        }));
      }
    }

    if (name === 'password' && confirmPassword && value !== confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: 'Parolele nu se potrivesc',
      }));
    }

    if (name === 'confirmPassword' && value === password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: '',
      }));
    }

    if (name === 'password' && validatePassword(value) && confirmPassword && value === confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: '',
      }));
    }

    if (name === 'password' && !validatePassword(value) && confirmPassword && value === confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: '',
      }));
    }

    if (name === 'confirmPassword' && !validatePassword(password)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: '',
      }));
    }

    if (name === 'password') {
      setPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newErrors = {};
    if (!password) newErrors.password = 'Acest câmp este obligatoriu';
    if (!confirmPassword) newErrors.confirmPassword = 'Acest câmp este obligatoriu';
    if (password && !validatePassword(password)) {
      newErrors.password = 'Parola trebuie să aibă minim 6 caractere, o literă mare și o cifră';
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Parolele nu se potrivesc';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toast.error('Există erori în formular. Vă rugăm să le corectați înainte de a trimite.');
      return;
    }

    try {
      await axios.post(`${config.BACKEND_URL}/reset-password/${token}`, { password });
      toast.success('Parola a fost resetată cu succes', { theme: 'light' });
      setTimeout(() => {
        navigate('/login');
      }, 2000); 
    } catch (error) {
      toast.error('Eroare la resetarea parolei!', { theme: 'light' });
    }
  };

  return (
    <div className="resetPasswordPage">
      <div className="container-reset">
        <h2 className="formTitle">Resetează-ți parola!</h2>
        <form onSubmit={handleSubmit}>
          <div className="inputs">
            <TextField
              id="outlined-required"
              label="Parola nouă"
              name="password"
              type="password"
              value={password}
              onChange={handleChange}
              sx={{ width: '100%' }}
              error={!!errors.password}
              helperText={errors.password}
            />
            <TextField
              id="outlined-required"
              label="Confirmă noua parolă"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={handleChange}
              sx={{ width: '100%' }}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
            />
          </div>
          <button id="submitButton" type="submit">Resetează parola</button>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
