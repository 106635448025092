import React, { useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@mui/material/TextField';
import './Register.css';
import Button from '@mui/joy/Button';
import SvgIcon from '@mui/joy/SvgIcon';
import { styled } from '@mui/joy';
import { Link } from 'react-router-dom';

const config = require('../../config');


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function Register() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    birthDate: "",
    gender: "",
    faculty: "",
    userType: "",
    idCard: null,
  });
  const [errors, setErrors] = useState({});
  const [idCardPreview, setIdCardPreview] = useState(null);
  const birthDateValidationTimeout = useRef(null);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
    return age;
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,}$/;
    return passwordRegex.test(password);
  };

  const validateFileExtension = (file) => {
    const validExtensions = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    return validExtensions.includes(file.type);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    if (name === 'firstName' || name === 'lastName') {
      if (value && value.length > 30) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Nu poate avea mai mult de 30 de caractere',
        }));
        return;
      } else if (value && !/^[a-zA-Z-\s]*$/.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Doar litere, spații și caracterul "-" sunt permise',
        }));
        return;
      }
    } else if (name === 'email' && value.length > 50) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Nu poate avea mai mult de 50 de caractere',
      }));
      return;
    } else if (name === 'birthDate') {
      const dateParts = value.split("-");
      if (dateParts[0] && dateParts[0].length > 4) {
        dateParts[0] = dateParts[0].slice(0, 4);
        e.target.value = dateParts.join("-");
      }

      if (birthDateValidationTimeout.current) {
        clearTimeout(birthDateValidationTimeout.current);
      }
      birthDateValidationTimeout.current = setTimeout(() => {
        if (value.length === 10) {
          const age = calculateAge(value);
          if (age < 18) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              birthDate: 'Trebuie să ai cel puțin 18 ani',
            }));
          } else if (age > 100) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              birthDate: 'Nu poți avea mai mult de 100 de ani',
            }));
          }
        }
      }, 500);
    } else if (name === 'password') {
      if (!validatePassword(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: 'Parola trebuie să aibă minim 6 caractere, o literă mare și o cifră',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: '',
        }));
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === 'birthDate') {
      const dateParts = value.split("-");
      if (dateParts[0] && dateParts[0].length > 4) {
        dateParts[0] = dateParts[0].slice(0, 4);
        e.target.value = dateParts.join("-");
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && !validateFileExtension(file)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        idCard: 'Doar fișiere de tip imagine sunt permise (JPEG, PNG, GIF, WEBP)',
      }));
      toast.error('Doar fișiere de tip imagine sunt permise (JPEG, PNG, GIF, WEBP)', { theme: 'light' });
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      idCard: file,
    }));
    setIdCardPreview(URL.createObjectURL(file));
    setErrors((prevErrors) => ({
      ...prevErrors,
      idCard: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'Acest câmp este obligatoriu';
    if (!formData.lastName) newErrors.lastName = 'Acest câmp este obligatoriu';
    if (!formData.email) newErrors.email = 'Acest câmp este obligatoriu';
    if (!formData.password) newErrors.password = 'Acest câmp este obligatoriu';
    if (!formData.birthDate) newErrors.birthDate = 'Acest câmp este obligatoriu';
    if (!formData.gender) newErrors.gender = 'Acest câmp este obligatoriu';
    if (!formData.faculty) newErrors.faculty = 'Acest câmp este obligatoriu';
    if (!formData.userType) newErrors.userType = 'Acest câmp este obligatoriu';
    if (!formData.idCard) newErrors.idCard = 'Nu ați încărcat o poză a legitimatiei de student/profesor';

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toast.error('Există erori în formular. Vă rugăm să le corectați înainte de a trimite.', { theme: 'light' });
      return;
    }

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      await axios.post(`${config.BACKEND_URL}/register`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Cererea de înregistrare a fost trimisă cu succes!");
      navigate("/pending-approval");
    } catch (error) {
      console.error("Error registering user:", error);
      if (error.response && error.response.data && error.response.data.error) {
        setErrors({ email: error.response.data.error });
        toast.error(error.response.data.error);
      } else {
        toast.error("Oops! Cererea de înregistrare nu a fost trimisă", { theme: "light" });
      }
    }
  };

  return (
    <div className="register-page">
      <div className="container-register">
        
        <h2 className="formTitle">Înregistrează-te!</h2>
        <form className="form" onSubmit={handleSubmit}>
          <div className="inputs">
            <TextField
              id="outlined-required"
              label="Nume"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              type="text"
              error={!!errors.lastName}
              helperText={errors.lastName}
            />
            <TextField
              id="outlined-required"
              label="Prenume"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              type="text"
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
            <TextField
              id="outlined-required"
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              type="email"
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              id="outlined-password-input"
              label="Parolă"
              name="password"
              value={formData.password}
              onChange={handleChange}
              type="password"
              error={!!errors.password}
              helperText={errors.password}
            />
            <TextField
              id="outlined-required"
              label="Data nașterii"
              name="birthDate"
              value={formData.birthDate}
              onChange={handleChange}
              onInput={handleInput}
              type="date"
              InputLabelProps={{ shrink: true }}
              error={!!errors.birthDate}
              helperText={errors.birthDate}
            />
            <TextField
              select
              id="outlined-required"
              label="Sex"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              SelectProps={{ native: true }}
              error={!!errors.gender}
              helperText={errors.gender}
            >
              <option value=""></option>
              <option value="Masculin">Masculin</option>
              <option value="Feminin">Feminin</option>
              <option value="Altul">Altul</option>
            </TextField>
            <TextField
              select
              id="outlined-required"
              label="Facultate"
              name="faculty"
              value={formData.faculty}
              onChange={handleChange}
              SelectProps={{ native: true }}
              error={!!errors.faculty}
              helperText={errors.faculty}
            >
              <option value=""></option>
              <option value="Facultatea de Inginerie">Facultatea de Inginerie</option>
              <option value="Facultatea de Arhitectură Navală">Facultatea de Arhitectură Navală</option>
              <option value="Facultatea de Știința și Ingineria Alimentelor">Facultatea de Știința și Ingineria Alimentelor</option>
              <option value="Facultatea de Automatică, Calculatoare, Inginerie Electrică și Electronică">Facultatea de Automatică, Calculatoare, Inginerie Electrică și Electronică</option>
              <option value="Facultatea de Educație Fizică și Sport">Facultatea de Educație Fizică și Sport</option>
              <option value="Facultatea de Litere">Facultatea de Litere</option>
              <option value="Facultatea de Științe și Mediu">Facultatea de Științe și Mediu</option>
              <option value="Facultatea de Istorie, Filosofie și Teologie">Facultatea de Istorie, Filosofie și Teologie</option>
              <option value="Facultatea de Economie și Administrarea Afacerilor">Facultatea de Economie și Administrarea Afacerilor</option>
              <option value="Facultatea de Drept și Științe Administrative">Facultatea de Drept și Științe Administrative</option>
              <option value="Facultatea de Medicină și Farmacie">Facultatea de Medicină și Farmacie</option>
              <option value="Facultatea de Arte">Facultatea de Arte</option>
              <option value="Facultatea Transfrontalieră">Facultatea Transfrontalieră</option>
            </TextField>
            <TextField
              select
              id="outlined-required"
              label="Ocupatie"
              name="userType"
              value={formData.userType}
              onChange={handleChange}
              SelectProps={{ native: true }}
              error={!!errors.userType}
              helperText={errors.userType}
            >
              <option value=""></option>
              <option value="Student">Student</option>
              <option value="Profesor">Profesor</option>
            </TextField>
            <div className="upload-button-container">
              <Button
                component="label"
                variant="outlined"
                color="neutral"
                startDecorator={
                  <SvgIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                  </SvgIcon>
                }
              >
                Încarcă o poză a legitimatiei de student/profesor
                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
              </Button>
            </div>
            {!!errors.idCard && <div className="error">{errors.idCard}</div>}
            {idCardPreview && (
              <div>
                <img className="preview" src={idCardPreview} alt="ID Card Preview" />
              </div>
            )}
          </div>
          <Button id="submitButton" type="submit" variant="contained" fullWidth>Trimite</Button>
          <div className="displayLinks">
            <p>Ai deja cont? <Link to="/login">Loghează-te</Link></p>
          </div>
        </form>
      </div>
    </div>
  );
}
