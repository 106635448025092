import React, { useState, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import './Recharge.css';
import { UserContext } from '../utils/UserContext';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const stripePromise = loadStripe('pk_test_51PQJDEP2JvdHvl5BahHdoOaIESztqC2kPkQkASwpritIprm7Me4ftPkLny8Ksu4AQhO0tg4NFnsCD2hLx4mssunB00HKr41RiY'); // Înlocuiește cu cheia ta publică Stripe
const config = require('../../config');

const Recharge = ({ isOpen, onClose }) => {
  const [amount, setAmount] = useState('');
  const [errors, setErrors] = useState({});
  const { user } = useContext(UserContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const stripe = await stripePromise;

    if (!user || !user.userID) {
      toast.error("Plata nu poate fi efectuată. Vă rugăm să vă autentificați.", { theme: 'light' });
      return;
    }

    if (!amount) {
      setErrors({ amount: 'Acest câmp este obligatoriu' });
      toast.error("Formularul conține erori. Vă rugăm să le corectați înainte de a trimite.", { theme: 'light' });
      return;
    }

    const amountValue = parseFloat(amount);
    if (isNaN(amountValue) || amountValue <= 0 || amountValue > 999999) {
      setErrors({ amount: 'Valoarea introdusă este negativă sau prea mare' });
      toast.error("Formularul conține erori. Vă rugăm să le corectați înainte de a trimite. ", { theme: 'light' });
      return;
    }

    if (amountValue === 1) {
      setErrors({ amount: 'Suma minimă de reîncărcare a soldului este 2 lei' });
      toast.error("Formularul conține erori. Vă rugăm să le corectați înainte de a trimite.", { theme: 'light' });
      return;
    }

    
    const response = await axios.post(`${config.BACKEND_URL}/create-checkout-session`, {
      items: [{
        name: 'Alimentare sold',
        price: amountValue,
        quantity: 1,
      }],
      userId: user.userID, 
    });

    const session = response.data;

    
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    const amountValue = parseFloat(value);
    if (!value) {
      setErrors({ amount: 'Acest câmp este obligatoriu' });
    } else if (isNaN(amountValue) || amountValue <= 0 || amountValue > 999999) {
      setErrors({ amount: 'Valoarea introdusă este negativă sau prea mare' });
    } else if (amountValue === 1) {
      setErrors({ amount: 'Valoarea introdusă nu poate fi egală cu 1' });
    } else {
      setErrors({});
    }
    setAmount(value);
  };

  const handleClose = () => {
    setAmount('');
    setErrors({});
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Alimentează sold</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Suma"
            type="number"
            value={amount}
            onChange={handleAmountChange}
            onWheel={(e) => e.target.blur()}
            fullWidth
            margin="normal"
            error={!!errors.amount}
            helperText={errors.amount}
            InputLabelProps={{ shrink: true }}
          />
          <Button variant="contained" color="primary" type="submit" fullWidth style={{ marginTop: '16px', border: 'none', textTransform:'none', fontFamily:'Outfit, sans-serif' }}>
            Plătește
          </Button>
          <Button variant="outlined" onClick={handleClose} fullWidth style={{ marginTop: '8px', textTransform:'none', fontFamily:'Outfit, sans-serif' }}>
            Închide
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Recharge;
