import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EditMenuItem from './EditMenuItem';
import CreateMenuItem from './CreateMenuItem';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import UseCustomMediaQuery from '../utils/UseCustomMediaQuery';
import './MenuItems.css';

const config = require('../../config');


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  textAlign: 'center', 
}));

const CenteredTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center', 
}));

const CenteredImageCell = styled(CenteredTableCell)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

function MenuItem() {
  const [menuItems, setMenuItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [currentDescription, setCurrentDescription] = useState('');
  const [createModalOpen, setCreateModalOpen] = useState(false); 
  const navigate = useNavigate();
  const isMobile = UseCustomMediaQuery('(max-width: 600px)');

  useEffect(() => {
    async function fetchMenuItems() {
      try {
        const response = await axios.get(`${config.BACKEND_URL}/menu`);
        setMenuItems(response.data);
      } catch (error) {
        console.error('Error fetching menu items', error);
      }
    }
    fetchMenuItems();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${config.BACKEND_URL}/menu/${id}`);
      setMenuItems(menuItems.filter(item => item._id !== id));
    } catch (error) {
      console.error('Error deleting menu item', error);
    }
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
  };

  const handleDescriptionOpen = (description) => {
    setCurrentDescription(description);
    setDescriptionOpen(true);
  };

  const handleDescriptionClose = () => {
    setDescriptionOpen(false);
    setCurrentDescription('');
  };

  const handleModalClose = () => {
    setSelectedItem(null);
  };

  const handleItemUpdate = (updatedItem) => {
    setMenuItems(menuItems.map(item => (item._id === updatedItem._id ? updatedItem : item)));
  };

  const handleCreateModalOpen = () => {
    setCreateModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setCreateModalOpen(false);
  };

  const handleNewItem = (newItem) => {
    setMenuItems([...menuItems, newItem]);
  };

  const itemsByCategory = menuItems.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {});

  return (
    <div className="menu-item-list">
      <Button style={{textTransform:"none", fontFamily:'Outfit, sans-serif'}} variant="contained" color="primary" onClick={handleCreateModalOpen}>
        Adaugă produs
      </Button>
      {Object.keys(itemsByCategory).map(category => (
        <div key={category} className="category-section">
          <h3 className="category-title">{category}</h3>
          <TableContainer component={Paper} className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Poza</StyledTableCell>
                  <StyledTableCell>Nume</StyledTableCell>
                  <StyledTableCell>Categorie</StyledTableCell>
                  <StyledTableCell>Preț</StyledTableCell>
                  <StyledTableCell>Cantitate</StyledTableCell>
                  <StyledTableCell>Acțiuni</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemsByCategory[category].map(item => (
                  <TableRow key={item._id}>
                    <CenteredImageCell>
                      <img src={`${config.BACKEND_URL}${item.imageUrl}`} alt={item.name} className="menu-item-image" />
                    </CenteredImageCell>
                    <CenteredTableCell>{item.name}</CenteredTableCell>
                    <CenteredTableCell>{item.category}</CenteredTableCell>
                    <CenteredTableCell>{item.price.toFixed(2)} RON</CenteredTableCell>
                    <CenteredTableCell>{item.quantity} {item.unit}</CenteredTableCell>
                    <CenteredTableCell>
                      <IconButton color="primary" onClick={() => handleEdit(item)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton color="secondary" onClick={() => handleDelete(item._id)}>
                        <DeleteIcon />
                      </IconButton>
                      <IconButton color="default" onClick={() => handleDescriptionOpen(item.description)}>
                        <InfoIcon />
                      </IconButton>
                    </CenteredTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ))}
      {selectedItem && (
        <EditMenuItem
          item={selectedItem}
          onClose={handleModalClose}
          onUpdate={handleItemUpdate}
        />
      )}
      <Dialog
        open={descriptionOpen}
        onClose={handleDescriptionClose}
        aria-labelledby="description-dialog-title"
      >
        <DialogTitle id="description-dialog-title">Ingrediente:</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {currentDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDescriptionClose} color="primary">
            Închide
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={createModalOpen}
        onClose={handleCreateModalClose}
        aria-labelledby="create-dialog-title"
      >
        <DialogContent>
          <CreateMenuItem onClose={handleCreateModalClose} onCreate={handleNewItem} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MenuItem;
